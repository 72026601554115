import React from 'react';

import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import StopIcon from '@material-ui/icons/Stop';


const DummyColorPicker = (props) => {

    const value = props.value ? props.value.startsWith('#') ? props.value.substr(1) : props.value : props.value

    const configTextfield = {
        ...props,
        value: value,
        fullWidth: true,
        variant: 'outlined'
    };


    const color = "#"+ value

    return (
        <>
            <TextField
                {...configTextfield}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <div>#</div>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end" variant="filled" onClick={props.onClick} >
                            <IconButton
                            >
                                <StopIcon htmlColor={color} fontSize="large"/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />


        </>
    );
};

export default DummyColorPicker;
import React, {useContext} from "react";
import classnames from "classnames";

import {
    Route,
    Switch, useHistory,
    withRouter,
} from "react-router-dom";


// styles
import useStyles from "./styles";

// logo

import RequestsTable from "pages/requestTable";

import { useLayoutState } from "context/LayoutContext";

import Header from "layouts/AppLayout/Header";
import Sidebar from "layouts/AppLayout/Sidebar";
import EditForm from "pages/editForm";
import ViewRequest from "pages/viewRequest";
import ViewUsers from "pages/viewUsers";
import ViewAccountUser from "pages/viewAccountUser";
import EditUser from "pages/editUser";
import ChangePassword from "pages/changePasswordLoggedIn";
import {AuthContext, RoleBasedRoute} from "context/authContext";
import ViewPlans from "pages/viewPlans";
import TrialBar from "components/containers/TrialBar";
import EndOfTrialDialog from "components/containers/EndOfTrialDialog";
import IdleTimer from "react-idle-timer";
import {SubscriptionContext} from "context/SubscriptionContext";
import PageLoader from "components/containers/PageLoader";


const IDLE_TIMEOUT = 1000 * 60 * 15;

const AppLayout = (props) => {
    const classes = useStyles();

    let layoutState = useLayoutState();

    const authContext = useContext(AuthContext)
    const {subscription} = useContext(SubscriptionContext)
    const history = useHistory();

    if(!subscription){
        return <PageLoader />
    }

    const onIdle = ()=>{
        authContext.signOut()
        history.push("/login")
    }

    return (
        <div className={classes.root}>
            <IdleTimer onIdle={onIdle} debounce={250} timeout={IDLE_TIMEOUT} crossTab={{emitOnAllTabs:true}}>
            <>
                <Header history={props.history} />
                <Sidebar />
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />

                    <TrialBar />
                    <EndOfTrialDialog />

                    <Switch>

                        <Route path="/requests" component={RequestsTable} />
                        <Route path="/accounts" component={ViewAccountUser}/>
                        <Route path="/changePassword" component={ChangePassword} />
                        <RoleBasedRoute path="/form" component={EditForm} roles={["admin"]}/>
                        <Route path="/view-request/:id" component={ViewRequest} />
                        <RoleBasedRoute path="/users" component={ViewUsers} roles={["admin"]} />
                        <RoleBasedRoute path="/edit-user/:id" component={EditUser} roles={["admin"]} />
                        <RoleBasedRoute path="/edit-user" component={EditUser} roles={["admin"]} />
                        <RoleBasedRoute path="/plans" component={ViewPlans} roles={["admin"]} />
                        <Route path="/" component={RequestsTable} />

                    </Switch>

                </div>
            </>
            </IdleTimer>
        </div>
    );
}

export default withRouter(AppLayout);

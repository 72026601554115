import React, { useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Link, TextField,Button
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import {Error, FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {useHistory} from "react-router-dom";
import {isOk, post_to_api} from "util/api";
import {getItem, setItem} from "util/cache";
import Logo from "components/form/Logo";
import FormButton from "components/form/Button"
import {isValidEmail} from "util/text";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const INITIAL_FORM_STATE = {
    code: ''
};

const FORM_VALIDATION = Yup.object().shape({
    code: Yup.string()
        .required("Verification code is required"),
});

const VerifyUserEmail = (props) => {
    const classes = useStyles();

    const {popiForm}= props;

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();


    const requestId = getItem("requestId");

    const requestEmail = getItem("requestEmail");

    const [emailDialogueOpen, setEmailDialogueOpen] = useState(false);

    const handleClickEmailDialogueOpen = (event) => {
        event.preventDefault();
        setEmailDialogueOpen(true);
    };

    const handleCloseEmailDialogue = () => {
        setEmailDialogueOpen(false);
        setChangingEmail(false);
    };
    const [changeEmailError, setChangeEmailError] = React.useState(null);
    const [newEmail, setNewEmail] = React.useState('');
    const [changingEmail, setChangingEmail] = React.useState(false);
    const handleNewEmailChange = (event) => {
        setNewEmail(event.target.value);
    };

    const resendCode = async (event)=>{
        event.preventDefault();
        try {

            let response = await post_to_api({
                requestId: requestId
            }, "/popi-form-api/user-form/resend-code", false);
            if(isOk(response.status)) {
                enqueueSnackbar(`Verification code sent, please check your email`,{
                    variant: 'success',
                })
            }else{
                enqueueSnackbar(`Failed to resend verification code, please try again`,{
                    variant: 'error',
                })
            }
        }catch (e) {
            console.error("Error resending verification code", e)
            enqueueSnackbar(`Failed to resend verification code, please try again`,{
                variant: 'error',
            })
        }
    }

    const changeEmail = async ()=>{
        if(!isValidEmail(newEmail)){
            setChangeEmailError("Email is not valid");
            return;
        }
        setChangeEmailError(null);
        setChangingEmail(true);
        try {
            let response = await post_to_api({
                requestId: requestId,
                email: newEmail
            }, "/popi-form-api/user-form/change-email", false);
            if(isOk(response.status)) {
                enqueueSnackbar(`Verification code sent, please check your email`,{
                    variant: 'success',
                })
                setItem("requestEmail", newEmail)
                handleCloseEmailDialogue();
            }else{
                enqueueSnackbar(`Failed to change email, please try again`,{
                    variant: 'error',
                })
            }
        }catch (e) {
            console.error("Error changing email", e)
            enqueueSnackbar(`Failed to change email, please try again`,{
                variant: 'error',
            })
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>
                        <Logo url={popiForm.logo} />

                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {
                                    let response = await post_to_api({
                                        requestId: requestId,
                                        code: values.code
                                    }, "/popi-form-api/user-form/verify-request", false);
                                    if(isOk(response.status) && response.data.success) {
                                        enqueueSnackbar(`Email verified`, {
                                            variant: 'success',
                                        })
                                        history.push("/thank-you")
                                    }else{
                                        enqueueSnackbar(`Invalid code captured, Please try again`,{
                                            variant: 'error',
                                        })
                                        form.resetForm()
                                    }
                                }catch (e){
                                    enqueueSnackbar(`Invalid code captured, Please try again`,{
                                        variant: 'error',
                                    })
                                    form.resetForm()
                                }

                            }}
                        >
                            <Form autocomplete="off">

                                <Grid container spacing={2}>



                                    <Grid item xs={12}>
                                        <FormTitle>
                                            We’ve sent you a code via email
                                        </FormTitle>

                                        <FormDescription>
                                            {`To confirm your identity, we’ve sent a 6 digit code via email to
                                            ${requestEmail}. Please enter it below`}
                                        </FormDescription>

                                        <FormDescription>
                                            Did not receive it? <Link  style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={resendCode}>Send it again</Link>
                                        </FormDescription>

                                        <FormDescription>
                                            Entered your email address incorrectly? <Link  style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={handleClickEmailDialogueOpen}>Change it</Link>
                                        </FormDescription>

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            autocomplete="off"
                                            name="code"
                                            label="Verification code"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormButton >
                                            Submit
                                        </FormButton>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>


                        <Dialog open={emailDialogueOpen} onClose={handleCloseEmailDialogue} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change email</DialogTitle>
                            <DialogContent>
                                <FormDescription>
                                    Please enter your email address below.
                                </FormDescription>
                                <TextField
                                    autoFocus
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    value={newEmail}
                                    onChange={handleNewEmailChange}
                                    variant = "outlined"
                                    required
                                    fullWidth
                                />
                                {changeEmailError && <Error>{changeEmailError}</Error>}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseEmailDialogue} color="primary" disabled={changingEmail}>
                                    Cancel
                                </Button>
                                {!changingEmail && <Button onClick={changeEmail} color="primary">
                                    Change Email
                                </Button>}
                                {changingEmail && <CircularProgress size={24} color="primary" />}
                            </DialogActions>
                        </Dialog>

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default VerifyUserEmail;

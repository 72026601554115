import React, {useContext} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
} from '@material-ui/core';
import Button from 'components/form/Button';
import { FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import Textfield from "components/form/Textfield";
import TextArea from "components/form/TextArea";
import {isOk, post_to_api} from "util/api";


const INITIAL_FORM_STATE = {
    contact: '',
    phone: '',
    message: 'I am interested in your enterprise plan'
};

const FORM_VALIDATION = Yup.object().shape({
    contact: Yup.string()
        .required("Your name is required"),
    phone: Yup.string()
        .required("Your phone number is required"),
});

const ContactUs = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    return (

        <Formik
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, form) => {
                try {
                    let response = await post_to_api(values,"/popi-form-api/business/enterprise-plan-inquiry")
                    if(isOk(response.status)) {

                        enqueueSnackbar(`Thank you for your interest, we will be in touch soon`, {
                            variant: 'success',
                        })
                    }

                    if(props.callback){
                        props.callback();
                    }

                }catch (e){
                    console.log("Error", e)
                    enqueueSnackbar(`An error occurred, Please try again`,{
                        variant: 'error',
                    })
                    form.resetForm()
                }

            }}
        >
            <Form>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FormTitle>
                            Contact us about our Enterprise plan
                        </FormTitle>



                    </Grid>


                    <Grid item xs={12}>
                        <Textfield
                            name="contact"
                            label="Your name"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Textfield
                            name="phone"
                            label="Your phone number"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextArea
                            name="message"
                            label="Message"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                        >
                            Contact us
                        </Button>
                    </Grid>


                </Grid>

            </Form>
        </Formik>

    );
};

export default ContactUs;

import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from 'context/authContext'
import LoggedOutLayout from "layouts/LoggedOutLayout";
import AppLayout from "layouts/AppLayout";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import theme from "themes";
import {SnackbarProvider} from "notistack";
import {LayoutProvider} from "context/LayoutContext";
import React from "react";
import SubscriptionProvider from "context/SubscriptionContext";


const SignedInRoutes = () => (
    <SubscriptionProvider>
    <Router>
        <Switch>
            <Route path="/home" component={AppLayout} />
            <Route path="/" component={AppLayout} />
        </Switch>
    </Router>
    </SubscriptionProvider>
)

const SignedOutRoutes = () => (
    <Router>
        <Switch>
            <Route path="/login" component={LoggedOutLayout} />
            <Route path="/forgotPassword" component={LoggedOutLayout} />
            <Route path="/signup" component={LoggedOutLayout} />
            <Route path="/confirm" component={LoggedOutLayout} />
            <Route path="/resetPassword" component={LoggedOutLayout} />
            <Route path="/" component={LoggedOutLayout} />
        </Switch>
    </Router>
)

const AdminApp = () => {

    return (
        <LayoutProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                    <AuthProvider>
                        <AuthIsSignedIn>
                            <SignedInRoutes/>
                        </AuthIsSignedIn>
                        <AuthIsNotSignedIn>
                            <SignedOutRoutes/>
                        </AuthIsNotSignedIn>
                    </AuthProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </LayoutProvider>
    )

}

export default AdminApp;

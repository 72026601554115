import React,{useContext, useState} from 'react';
import {
    Container,
    Grid, Link
} from '@material-ui/core';
import {FormSection, FormTitle} from "components/typography";
import FormContainer from "components/containers/FormContainer";

import { AuthContext } from "context/authContext";
import {SubscriptionContext} from "context/SubscriptionContext";

import ChangePasswordLoggedIn from './changePasswordLoggedIn';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PasswordField from 'components/form/PasswordField'

import { withStyles } from '@material-ui/core/styles';
import Button from 'components/form/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from "components/form/Checkbox";
import {isOk, post_to_api} from "util/api";
import {SESSION_KEYS, setSessionItem} from "util/cache";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  



const INITIAL_FORM_STATE = {
    password: ''
};

const FORM_VALIDATION = Yup.object().shape({
    password: Yup.string()
        .required('Password is required'),
    termsOfService: Yup.boolean()
        .oneOf([true], 'The terms of service must be acknowledged.')
        .required('The terms of service must be acknowledged.'),
});



const ViewAccountUser = () => {
    const [changingPassword, setChangingPassword] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showChangePassword = () => {
        setChangingPassword(!changingPassword);
    }



    const authContext = useContext(AuthContext)
    const history = useHistory();

    const { subscription } = useContext(SubscriptionContext)

    const userEmail = `${authContext.email} `

    const userPlan = subscription? subscription.plan : {};
    
    const hrStyle = {
        width:'100%',
        height: '1px',
        backgroundColor: '#ccc',
        border: 'none'
      };
    
    const linkStyle = {
        textDecoration:'none',
        color: '#0645AD',
        cursor: 'pointer'
    }

    const titleModalStyle = {
        textAlign:'center',
        fontSize:'19px',
        color:'#000'
    }

    const buttonStyle = {
        background:'#0068AB',
        border:'unset',
        borderRadius:'unset',
        marginLeft:'auto',
        marginRight:'auto',
        color:'#fff',
        textTransform:'none',
        marginTop:'1.5em',
        marginBottom:'1.5em'
    }

    const dialogContentStyle = {
        textAlign:'center',
        border:'none',
    }

    const textDeletePassStyle = {
        fontSize:'none',
        fontWeight:'700',
        marginLeft:'-10em',
        marginTop:'1.5em',
        color:'#000'
    }

    const divSpanStyle = {
        fontSize: "0.95rem",
        color:'#000',
        marginBottom: '16px'
    }

    const { enqueueSnackbar } = useSnackbar();


    const validate = async (values) => {
        const errors = {};
        let passwordResponse = await post_to_api({password: values.password},"/popi-form-api/users/validate-password");
        if(!isOk(passwordResponse.status) || !passwordResponse.data.success){
           errors.password = "Incorrect password, Please try again"
        }
        return errors;
    }

    
    return (
        <Grid container>
            <Grid >
                <Container>
                    <FormContainer >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormTitle align="left">
                                Account settings 
                            </FormTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSection >
                                <span style={{fontWeight:700}}>Email</span><br/>
                                <span style={{fontWeight:400}}>{userEmail}</span>
                            </FormSection>
                        </Grid>
                        <hr style={hrStyle}/>
                        <Grid item xs={12}>
                            <FormSection >
                                <span style={{fontWeight:700}}>Password</span><br/>
                                <span style={{fontWeight:400}}>
                                    <Link onClick={showChangePassword} style={linkStyle}>Change Password</Link> 
                                </span>                              
                                {changingPassword && <ChangePasswordLoggedIn></ChangePasswordLoggedIn>}
                            </FormSection>
                        </Grid>
                        <hr style={hrStyle}/>
                        <Grid item xs={12}>
                            <FormSection >

                                <span style={{fontWeight:700}}>Your plan</span><br/>
                                {userPlan != null ?
                                    <span style={{fontWeight:400}}>{userPlan.name}</span>
                                    :
                                    <span>Free</span>
                                }   
                               <br/>
                                <span style={{fontWeight:400}}>
                                   <Link to='/plans' style={linkStyle}>Change plan</Link> 
                                </span>
                            </FormSection>
                        </Grid>
                        <hr style={hrStyle}/>
                        <Grid item xs={12}>
                            <FormSection >
                                <span style={{fontWeight:400}}>
                                    <Link style={linkStyle} onClick={handleClickOpen}>Permanently close your account</Link> 
                                </span>
                            </FormSection>
                        </Grid>
                        </Grid>
                    </FormContainer>
                </Container>
            </Grid>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <p  style={titleModalStyle}>Are you sure you want to close your account?</p>
                </DialogTitle>
                <DialogContent dividers style={dialogContentStyle}>
                <div style={divSpanStyle} >
                   All your data will be deleted, and you will no longer have access to any records.
                </div>
                <div style={divSpanStyle}>
                    Please note that while account closure will be immediate, your records will be fully deleted after 60 days
                </div>
                <div style={divSpanStyle}>
                   Enter your password below to confirm your intent to close your account
                </div>

                
                <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            onSubmit={async (values, form) => {

                                try {
                                    let errors = await validate(values);
                                    if(errors.password){
                                        form.setErrors(errors);
                                    }else {
                                        let response = await post_to_api(null, "/popi-form-api/business/close-account");
                                        if (isOk(response.status) && response.data.success) {
                                            enqueueSnackbar(`Account successfully deleted`, {
                                                variant: 'success',
                                            })
                                            authContext.signOut()
                                            history.push("/login")

                                        } else {
                                            enqueueSnackbar(`Close account failed, please try again`, {
                                                variant: 'error',
                                            })
                                        }
                                    }
                                } catch (e) {
                                    console.error("Error signing up", e)
                                    enqueueSnackbar(`Close account failed, please try again`, {
                                        variant: 'error',
                                    })
                                }
                            }}
                            validationSchema={FORM_VALIDATION}>
                    <Form>

                        <div style={divSpanStyle}>
                        <Checkbox
                            name="termsOfService"
                            color="primary"
                            label={<div>I acknowledge that all data associated with my account will be deleted and my records will unrecoverable
                            </div>}
                        />
                        </div>

                        <div style={divSpanStyle}>
                    <PasswordField
                        name="password"
                        label="Current Password"
                    />
                        </div>

                        <Button disableOnInvalid  >
                            Permanently delete my account
                        </Button>

                    </Form>            
                </Formik>

                </DialogContent>

            </Dialog>
        </Grid>
    )
}

export default ViewAccountUser;
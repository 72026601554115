import React from 'react';
import {
    Container,
    Grid, Link
} from '@material-ui/core';
import CardFormWrapper from "components/containers/CardFormWrapper";
import {FormDescription, FormTitle} from "components/typography";
import {Link as RouterLink} from "react-router-dom";
import Logo from "components/form/Logo";


const ThankYou = (props) => {
    const {popiForm}= props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <CardFormWrapper>
                        <Logo url={popiForm.logo} />
                            <div>

                                <Grid container spacing={2}>



                                    <Grid item xs={12}>


                                        <FormTitle>
                                            Thank you
                                        </FormTitle>

                                        <FormDescription>
                                            Your request has been submitted. We will notify you when the request is completed
                                        </FormDescription>

                                    </Grid>




                                    <Grid item xs={12}>
                                        <FormDescription>
                                            Do you have another data request? <Link component={RouterLink} to="/">Submit another request</Link>
                                        </FormDescription>
                                    </Grid>


                                </Grid>

                            </div>


                    </CardFormWrapper>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ThankYou;

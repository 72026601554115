import React, { useState, useEffect } from 'react'
import {get_from_api, isOk} from "util/api";
import {SubscriptionPlans, SubscriptionStatus} from "util/subscriptions";
import {formatColor, isObject} from "util/utils";

export const CompanyContext = React.createContext({})

const defaultColor = "#0068AB"

const CompanyProvider = (props)=>{

    const [popiForm, setPopiForm] = useState();
    const [formInitialised, setFormInitialised] = useState();

    const getSubDomain = () => {
        let hostname = window.location.hostname
        return hostname.substr(0, hostname.indexOf("."))
    }

    useEffect(()=>{
        const getPopiForm = async ()=>{
            let subDomain = getSubDomain();
            let response =  await get_from_api(`/popi-form-api/user-form/subdomain/${subDomain}`,false)
            if(isOk(response.status) && isObject(response.data)){
                let formData = response.data;
                let onFreePlan = false, trialEnded = false

                if(formData.subscription?.status === SubscriptionStatus.TrialEnded){
                    trialEnded = true
                }

                if(formData.subscription?.plan && formData.subscription.plan.name === SubscriptionPlans.Free){
                    onFreePlan = true
                }

                if(!formData.color || onFreePlan || trialEnded){
                    formData.color = defaultColor;
                }
                if(formData.color){
                    formData.color = formatColor(formData.color);
                }
                if(!formData.logo || onFreePlan || trialEnded){
                    formData.logo = "/popi.png"
                }
                setPopiForm(formData)
            }
            setFormInitialised(true);
        }
        getPopiForm();
    },[])

    return <CompanyContext.Provider value={{popiForm,formInitialised}}>{props.children}</CompanyContext.Provider>
}

export default CompanyProvider
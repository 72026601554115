import React from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "context/LayoutContext";


export default function Header(props) {
    let classes = useStyles();

    // global
    let layoutState = useLayoutState();
    let layoutDispatch = useLayoutDispatch();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButtonSandwich,
                        classes.headerMenuButtonCollapse,
                    )}
                >
                    {layoutState.isSidebarOpened ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Typography variant="h6" weight="medium" className={classes.logotype}>
                    POPI Fox
                </Typography>

            </Toolbar>
        </AppBar>
    );
}

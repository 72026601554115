import {makeStyles} from "@material-ui/core/styles";
import React, {useContext, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Typography} from '@material-ui/core';
import {SubscriptionContext} from "context/SubscriptionContext";
import dayjs from "dayjs";
import PaidPlanButton from "components/form/GetPlanButton/PaidPlanButton";
import FreePlanButton from "components/form/GetPlanButton/FreePlanButton";
import ContactUs from "components/fragments/contactus";
import CloseIcon from '@material-ui/icons/Close';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import {SubscriptionStatus} from "util/subscriptions";

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    centered: {
        textAlign: "center",
        width: "100%"
    },
    section: {
        marginBottom: theme.spacing(2)
    },
    listSection: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        textAlign: "center",
    },
    listItem :{
        listStyleType : "circle"
    },
    buttonSection: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const END_TRIAL_NOTIFICATION = 1;
const ENTERPRISE_INQUIRY = 2;
const ENTERPRISE_INQUIRY_THANK_YOU = 3;

const EndOfTrialDialog = () => {
    const classes = useStyles();
    const [tab,setTab] = useState(END_TRIAL_NOTIFICATION)

    const authContext = useContext(AuthContext)
    const history = useHistory();

    const {subscription, getSmallBusinessPlan, getFreePlan, plans, getSubscription, getPlans} = useContext(SubscriptionContext)

    const isOnTrial = React.useMemo(()=>{
        return !!(subscription && !subscription.plan);
    },[subscription])


    const trialEnded = React.useMemo(()=>{
        return !!(subscription && subscription.status === SubscriptionStatus.TrialEnded);
    },[subscription])


    const openEndTrialNotificationTab = ()=>{
        setTab(END_TRIAL_NOTIFICATION)
    }

    const openEnterpriseInquiryTab = ()=>{
        setTab(ENTERPRISE_INQUIRY)
    }

    const openEnterpriseInquiryThankYouTab = ()=>{
        setTab(ENTERPRISE_INQUIRY_THANK_YOU)
    }

    const logout = ()=>{
        authContext.signOut()
        history.push("/login")
    }

    const daysLeft = React.useMemo(()=>{
        if(subscription && subscription.trialEndDate){
            const endOfTrial = dayjs(subscription.trialEndDate).startOf("day");
            const today = dayjs().startOf("day");
            return endOfTrial.diff(today,'day')
        }
        return 0;
    },[subscription])

    if(!subscription || !plans){
        getSubscription()
        getPlans()
        return null;
    }

    const smallBusinessPlan = getSmallBusinessPlan();

    const freePlan = getFreePlan();

    return (
        <>

            { daysLeft < 0 && isOnTrial && trialEnded &&

            <Dialog
                open
                aria-labelledby="end-of-trial-dialog-title"
            >
                {tab === END_TRIAL_NOTIFICATION  && <>
                <DialogTitle id="end-of-trial-dialog-title"><div className={classes.title}>Thanks for trying out our Small Business features!</div></DialogTitle>
                <DialogContent>
                    <Grid direction="column"
                          justifyContent="center"
                          alignItems="center">
                        <div className={classes.section}>
                            <div className={classes.centered}>Your 7 day free trial of our Small Business plan has now concluded.</div>
                            <div className={classes.centered}>To continue using features like:</div>
                        </div>
                        <div className={classes.listSection}>
                            <ul >
                                <li>Custom branding</li>
                                <li>Up to 5 internal users</li>
                                <li>Automatic Notifications</li>
                                <li>Monthly Compliance Report</li>
                            </ul>
                        </div>

                        <div className={classes.section}>
                            <div className={classes.centered}>Please subscribe to our Small Business plan for only R200/mo or <Link
                                component="button"
                                onClick={openEnterpriseInquiryTab}
                            >
                                contact us
                            </Link> about our Enterprise plan.</div>
                        </div>
                        <div className={classes.section}>
                            <div  className={classes.centered}>Otherwise, you can continue to use our Free Plan without the above features.</div>
                        </div>
                        <div className={classes.buttonSection}>
                            <PaidPlanButton
                                fullWidth={false}
                                variant="contained"
                                text="Activate Small business plan (R200/mo)"
                                plan={smallBusinessPlan}
                            />
                        </div>

                        <div className={classes.buttonSection}>
                            <FreePlanButton
                                fullWidth={false}
                                variant="outlined"
                                text="Continue with limited Free Plan"
                                plan={freePlan}
                            />
                        </div>

                    </Grid>
                </DialogContent>
                </>}


                {tab === ENTERPRISE_INQUIRY  && <>


                    <DialogTitle id="end-of-trial-dialog-title">
                        <Typography variant="h6">&nbsp;</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={openEndTrialNotificationTab}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                       <ContactUs callback={openEnterpriseInquiryThankYouTab}/>
                    </DialogContent>
                </>}


                {tab === ENTERPRISE_INQUIRY_THANK_YOU  && <>


                    <DialogTitle id="end-of-trial-dialog-title">
                        <div>Thank you</div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{marginBottom: '16px'}}>We have received your enquiry for our enterprise plan. We will be in touch soon</div>
                        <Button variant="contained" color="primary" onClick={logout}>
                            Logout
                        </Button>
                    </DialogContent>
                </>}



            </Dialog>

            }
        </>
    )
}

export default EndOfTrialDialog;
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";
import PasswordCriteriaItem from "./PasswordCriteriaItem";
import {FormSection} from "components/typography";
import {useFormikContext} from "formik";
import usePasswordCriteria from "./usePasswordCriteria";

const PasswordCriteriaList = (props) => {

  const { values, setFieldValue } = useFormikContext();
  const passwordCriteria = usePasswordCriteria(values, props.passwordField);

  const pwd = values[props.passwordField]

  useEffect(()=>{
     if(passwordCriteria.isValid !== pwd){
         setFieldValue(props.passwordValidField,passwordCriteria.isValid)
     }
  },[pwd, passwordCriteria.isValid, setFieldValue, props.passwordValidField])

  return (
    <>
      <FormSection>
        Password must contain at least:{" "}
      </FormSection>
      <List dense disablePadding>
        {passwordCriteria.rules.map((rule, index) => {
          return (
            <PasswordCriteriaItem
              key={index}
              characterType={rule.characterType}
              numOfCharactersRequired={rule.numOfCharacters}
              password={passwordCriteria.password}
            />
          );
        })}
      </List>
    </>
  );
};

PasswordCriteriaList.propTypes = {
  passwordField: PropTypes.string.isRequired,
  passwordValidField: PropTypes.string.isRequired,
};

export default PasswordCriteriaList;

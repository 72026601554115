import React, {useEffect} from "react";
import config from 'configs'
import AdminApp from "AdminApp";
import FormApp from "FormApp";
import CompanyProvider from "context/CompanyContext";
import ReactGA from "react-ga"

const adminDomain = config['DOMAIN']

const App = () => {

   const hostname = window.location.hostname
   const subdomain =  hostname?.split(".")[0].toLowerCase()
   const isAdmin = hostname === adminDomain || subdomain === "app"

   useEffect(()=>{
       ReactGA.initialize(config['GOOGLE_ANALYTICS_ID']);
   },[])

   if(isAdmin){
       return <AdminApp />
   }

   return (
       <>
          <CompanyProvider>
             <FormApp />
          </CompanyProvider>
       </>

   )

}

export default App;

import {makeStyles} from "@material-ui/core/styles";
import React, {useContext} from "react";
import { Link} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";
import {SubscriptionContext} from "context/SubscriptionContext";
import dayjs from "dayjs";


const useStyles = makeStyles((theme) => ({
    warning: {
        color: '#000000',
        backgroundColor: '#FFEAA0',
        padding: '12px 20px'
    },
}));

const TrialBar = () => {
    const classes = useStyles();

    const {subscription} = useContext(SubscriptionContext)


    const isOnTrial = React.useMemo(()=>{
        return !!(subscription && !subscription.plan);
    },[subscription])


    const daysLeft = React.useMemo(()=>{
        if(subscription && subscription.trialEndDate){
           const endOfTrial = dayjs(subscription.trialEndDate).startOf("day");
           const today = dayjs().startOf("day");
           return endOfTrial.diff(today,'day')
        }
        return 0;
    },[subscription])

    const message = daysLeft === 0 ? `Your trial ends today.` : `You have ${daysLeft} more ${daysLeft === 1 ? 'day' : 'days'} to try out our Small Business plan features.`

    if(!subscription || subscription.plan){
        return null;
    }

    return (
        <>
            {isOnTrial && daysLeft >= 0 &&
        <div className={classes.warning}>
            {message} <Link component={RouterLink} to="/plans">Learn more about our plans</Link>.
        </div>}
        </>
    )
}

export default TrialBar
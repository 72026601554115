import axios from "axios";

import config from 'configs'
import {getAccessToken} from "util/utils";

export const isOk = (status) => status >= 200 && status < 300;

const apiUrl = config['API_URL']

const PATH_PREFIX = "";

const api_call = async (options, auth = true) => {
    let accessToken = await getAccessToken()
    if (auth && accessToken) {
        options.headers["Authorization"] = "Bearer " + accessToken;
    }

    try {
        return await axios(options).catch(function (error) {
            if (error.response) {
                return Promise.resolve(error.response);
            } else {
                return Promise.reject(error);
            }
        });
    } catch (error) {
        console.log("Error", error);
        return {
            status: 500,
            data: error.message,
        };
    }
};

export const post_to_api = async (body, path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data: body,
        url: url,
    };
    return await api_call(options, auth);
}

async function download_file(options, auth, filename) {
    try {
        let response = await api_call(options, auth);
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        return { status: 200 };
    } catch (e) {
        return { status: 500 };
    }
}

export const post_to_download_api = async (
    body,
    path,
    filename,
    auth = true
) => {
    let url = apiUrl + PATH_PREFIX + path;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data: body,
        url: url,
        responseType: "blob",
    };
    return await download_file(options, auth, filename);
};

export const get_to_download_from_api = async (path, filename, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        url: url,
        responseType: "blob",
    };

    return await download_file(options, auth, filename);
};

export const post_file_to_api = async (body, path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;
    let formData = new FormData();
    formData.append("file", body);
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
        },
        data: formData,
        url: url,
    };
    return await api_call(options, auth);
};

export const put_to_api = async (body, path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data: body,
        url: url,
    };
    return await api_call(options, auth);
};

export const get_from_api = async (path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        url: url,
    };

    return await api_call(options, auth);
};

export const delete_from_api = async (path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;

    const options = {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
        url: url,
    };

    return await api_call(options, auth);
};

export const delete_from_api_with_body = async (body, path, auth = true) => {
    let url = apiUrl + PATH_PREFIX + path;

    const options = {
        method: "DELETE",
        data: body,
        headers: {
            Accept: "application/json",
        },
        url: url,
    };

    return await api_call(options, auth);
};

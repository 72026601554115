import React from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import {FormFieldLabel} from "components/typography";

const RadioButtonGroupWrapper = ({
                           name,
                           options,
                            label,
                           ...otherProps
                       }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = evt => {
        const { value } = evt.target;
        setFieldValue(name, value);
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (

    <FormControl {...configSelect}>
        <FormFieldLabel >{label}</FormFieldLabel>
        <RadioGroup aria-label={name} name={name} value={meta.value} onChange={handleChange}>

            {options.map((item, pos) => {
                return (
                    <FormControlLabel key={pos} value={item.value}  control={<Radio color="primary" />} label={item.label}>
                        {item.label}
                    </FormControlLabel>
                )
            })}

        </RadioGroup>
        {configSelect.helperText && <FormHelperText>{configSelect.helperText}</FormHelperText>}
        {configSelect.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
    );
};

export default RadioButtonGroupWrapper;

import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles =  makeStyles(theme => ({
    logoContainer: {
        textAlign: "center",
        padding: theme.spacing(1)
    },
    logoImage: {
        maxHeight: "100px",
        width: "auto"
    },
}));



const Logo = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.logoContainer}>
            <img src={props.url} className={classes.logoImage} alt={"logo"}/>
        </div>
    )
}

export default Logo


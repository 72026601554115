import React from "react";
import {
  Grid,
} from "@material-ui/core";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";


// styles
import useStyles from "./styles";

// logo
import Login from "pages/login";
import Signup from "pages/signup";
import VerifyEmail from "pages/verifyEmail";
import ChangePassword from "pages/changePassword";
import ForgotPassword from "pages/forgotPassword";
import Confirm from "pages/confirm";
import ResetPassword from "pages/resetPassword";


const LoggedOutLayout = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer} >
        <div>

          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/verifyEmail" component={VerifyEmail} />
            <Route path="/changePassword" component={ChangePassword} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/confirm" component={Confirm} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/" component={Login} />
          </Switch>

        </div>

      </div>
    </Grid>
  );
}

export default withRouter(LoggedOutLayout);

import React, {useContext} from 'react';
import {
    Button
} from '@material-ui/core';
import { usePaystackPayment } from 'react-paystack';
import config from "configs";
import {isOk, post_to_api} from "util/api";
import {useSnackbar} from "notistack";
import {SubscriptionContext} from "context/SubscriptionContext";
import {toMediumDate} from "util/dates";

const publicKey = config['PAYSTACK_PUBLIC_KEY']


const PaidPlanButton = (props) => {
    let {plan, callback, planChange} = props

    const {subscription,getSubscription} = useContext(SubscriptionContext)

    const { enqueueSnackbar } = useSnackbar();

    const initializePayment = usePaystackPayment({
        reference: `${subscription.business.id}_${(new Date()).getTime().toString()}`,
        email: subscription.business.email,
        firstname: subscription.business.name,
        amount: plan?.monthlyPrice.amount,
        plan: plan?.code,
        publicKey: publicKey
    });


    const processPayment = async (trn) => {
        let response = await post_to_api({transactionId: trn.transaction },`/popi-form-api/subscriptions/process-payment`)
        if(isOk(response.status) && response.data.success) {
            enqueueSnackbar(`${plan.name} activated`, {
                variant: 'success',
            })
            getSubscription();
        }
        if(callback){
            callback();
        }
    }


    const onSuccess = (trn) => {
        processPayment(trn)

    };

    if(planChange && planChange.plan && planChange.plan?.id === plan?.id){
        return (
            <Button fullWidth variant="outlined" color="primary"
            >
                Active from {toMediumDate(planChange.changeDate)}
            </Button>
        )
    }


    return (
        <>
            <Button fullWidth={props.fullWidth || true} variant={props.variant || "contained"} color="primary"
                    onClick={() => {
                        initializePayment(onSuccess)
                    }}>
                {props.text || "Get started" }
            </Button>
        </>

    );
};

export default PaidPlanButton;
import React, { useState, useEffect, useContext } from 'react'

import * as cognito from 'libs/cognito'
import {Route} from "react-router-dom";
import NotAuthorised from "components/fragments/security/NotAuthorised";
import {get_from_api, isOk} from "util/api";
import PageLoader from "components/containers/PageLoader";
import {SESSION_KEYS, setAndGetItem, setItem} from "util/cache";

export const AuthStatus = {
    Loading: "Loading",
        SignedIn: "SignedIn",
        SignedOut: "SignedOut",
}

const defaultState = {
    sessionInfo: {},
    email: '',
    subscription: null,
    authStatus: AuthStatus.Loading,
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }) => {
    const { authStatus } = useContext(AuthContext)

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }) => {
    const { authStatus } = useContext(AuthContext)

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

export const HasRole = (props) => {
    const { userGroups } = useContext(AuthContext)
    const {role, children} = props
    if(!role){
        return <>{children}</>
    }

    return <>{ userGroups.includes(role) ? children : null}</>
}

export const HasAnyRole = (props) => {
    const { userGroups } = useContext(AuthContext)
    const {roles, children} = props

    if(!roles){
        return <>{children}</>
    }

    return <>{ userGroups.some(r=> roles.indexOf(r) >= 0) ? children : null}</>
}

export const RoleBasedRoute = ({component: Component, ...rest}) => {
    const { userGroups } = useContext(AuthContext)
    const {roles} = rest
    const hasRole = !roles || userGroups.some(r=> roles.indexOf(r) >= 0)
    return (

        <Route {...rest} render={props => (
            hasRole ?
                <Component {...props} />
                : <NotAuthorised />
        )} />
    );
};

const AuthProvider = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
    const [sessionInfo, setSessionInfo] = useState({})
    const [email, setEmail] = useState({})
    const [subscription, setSubscription] = useState(null);
    const [attrInfo, setAttrInfo] = useState([])
    const [userGroups,setUserGroups] = useState([])
    const [initiliased, setInitialised] = useState(false);

    const getSessionInfo = async () =>{
        try {
            const session = await getSession()
            setSessionInfo({
                accessToken: session?.accessToken?.jwtToken,
                refreshToken: session?.refreshToken?.token,
                ...session?.idToken?.payload
            })

            const attr = await getAttributes()
            setAttrInfo(attr)
            setAuthStatus(AuthStatus.SignedIn)
            setEmail(session?.idToken?.payload?.email)
            setUserGroups(session?.idToken?.payload["cognito:groups"] || [])
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut)
        }
    }


    useEffect(() => {
        getSessionInfo()
    }, [setAuthStatus, authStatus])

    useEffect(() => {
        getSubscription()
    },[])

    if (authStatus === AuthStatus.Loading) {
        return null
    }

    async function signInWithEmail(username, password) {
        try {
            setEmail(username)
            await cognito.signInWithEmail(username, password)
            setAuthStatus(AuthStatus.SignedIn)
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut)
            throw err
        }
    }

    async function signUpWithEmail(username, email, password, attributes) {
        // eslint-disable-next-line no-useless-catch
        try {
            setEmail(email)
            return await cognito.signUpUserWithEmail(username, email, password, attributes)
        } catch (err) {
            throw err
        }
    }

   async function getSubscription()  {
        console.log("AuthContext getSubscription")
        let response = await get_from_api("/popi-form-api/subscriptions/")
        if(isOk(response.status)) {
            setSubscription(response.data.plan)
        }
        setInitialised(true)
    }

    if(!initiliased){
        return <PageLoader />
    }

    function signOut() {
        cognito.signOut()
        setAuthStatus(AuthStatus.SignedOut)
    }

    async function verifyCode(username, code) {

       return await cognito.verifyCode(username, code)

    }

    async function getSession() {

            const session = await cognito.getSession()
            return session

    }

    async function getAttributes() {

            const attr = await cognito.getAttributes()
            return attr

    }

    async function setAttribute(attr) {

            const res = await cognito.setAttribute(attr)
            return res

    }

    async function sendCode(username) {

            await cognito.sendCode(username)

    }
    
    async function forgotPassword(username) {

            await cognito.forgotPassword(username)

    }
    
    async function confirmPassword(username, code, password) {

        await cognito.confirmPassword(username, code, password)

}

    async function changePassword(oldPassword, newPassword) {

            await cognito.changePassword(oldPassword, newPassword)

    }


    const state = {
        authStatus,
        sessionInfo,
        attrInfo,
        email,
        subscription,
        userGroups,
        signUpWithEmail,
        signInWithEmail,
        signOut,
        verifyCode,
        getSession,
        sendCode,
        setEmail,
        setSubscription,
        forgotPassword,
        changePassword,
        confirmPassword,
        getAttributes,
        setAttribute,
        getSessionInfo
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider

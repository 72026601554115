import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    container: {
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: `${theme.palette.primary.main}10`,

    },
    logotypeContainer: {
        backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    logotypeImage: {
        width: 165,
        marginBottom: theme.spacing(4),
    },
    logotypeText: {
        color: "white",
        fontWeight: 500,
        fontSize: 84,
        [theme.breakpoints.down("md")]: {
            fontSize: 48,
        },
    },
    formContainer: {
        width: "60%",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 15px 13px rgba(0, 104, 171, 0.1)",
        margin: "2em",
        [theme.breakpoints.down("md")]: {
            width: "60%",
            margin: "0"
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: "0"
        },
    },
    tab: {
        fontWeight: 400,
        fontSize: 18,
    },
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    googleButton: {
        marginTop: theme.spacing(6),
        boxShadow: theme.customShadows.widget,
        backgroundColor: "white",
        width: "100%",
        textTransform: "none",
    },
    googleButtonCreating: {
        marginTop: 0,
    },
    googleIcon: {
        width: 30,
        marginRight: theme.spacing(2),
    },
    creatingButtonContainer: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    createAccountButton: {
        height: 46,
        textTransform: "none",
    },
    formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center",
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + "40",
    },
    errorMessage: {
        textAlign: "center",
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    formButtons: {
        width: "100%",
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    forgetButton: {
        textTransform: "none",
        fontWeight: 400,
    },
    loginLoader: {
        marginLeft: theme.spacing(4),
    },
    copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: theme.spacing(2),
        },
    },
    footerImage: {
        maxHeight: '30px',
        width: 'auto',
        marginRight: '16px'
    },
    poweredBy: {
        fontSize: "16px",
        color: '#625555'
    },
    footerLink: {
        color: '#625555',
        cursor: "pointer",
        textDecoration: "underline"
    },
    footerText: {
        marginRight: "20px",
        color: '#625555',
        fontSize: "14px"
    },
    pipe: {
        margin: "0 16px"
    },
    footerContainer: {
        padding: "16px 0"
    },
    imageContainer: {
        padding: "24px 0 12px"
    }
}));

export const SESSION_KEYS = {
    USERNAME: "username",
    EMAIL: "email",
    NEW_PASSWORD : "newPassword",
    ACCESS_TOKEN : 'accessToken',
    TOKEN_EXP_AT : 'tokenExpAt',
    REFRESH_TOKEN :'refreshToken'
}

export const setItem = (key, value)=>{
    window.localStorage.setItem(key, value)
}


export const getItem = (key)=>{
   return  window.localStorage.getItem(key)
}

export const removeItem = (key)=>{
    window.localStorage.removeItem(key)
}


export const setSessionItem = (key, value)=>{
    window.sessionStorage.setItem(key, value)
}

export const getSessionItem = (key)=>{
    return  window.sessionStorage.getItem(key)
}

export const removeSessionItem = (key)=>{
    window.sessionStorage.removeItem(key)
}
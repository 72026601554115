import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import get from "lodash.get";


const EMPTY_VALUE = "--";
const ACTION_VALUE = "action";

const ObjectSelectWrapper = ({
  name,
  valueField,
  labelField,
  label,
                               placeholder,
  labelFunction,
                               disableFunction,
  options,
  ...otherProps
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    let itemValue = event.target.value;

    if (itemValue === ACTION_VALUE || itemValue === EMPTY_VALUE) {
      return;
    }
    let value = null;
    if (itemValue !== EMPTY_VALUE) {
      for (let i = 0; i < options.length; i++) {
        if (get(options[i], valueField) === itemValue) {
          value = options[i];
          break;
        }
      }
    }
    setFieldValue(name, value);
  };

  const optionsList = () => {
    let menuItemsList = [];
    let empty = (
        <MenuItem key="empty" value={EMPTY_VALUE} disabled={true}>
          {placeholder ? placeholder : `Select ${label}`}
        </MenuItem>
    );
    if (options) {
      menuItemsList = options.map((item, index) => {
        return (
            <MenuItem key={index} value={get(item, valueField)} disabled={disableFunction ? disableFunction(item) : false }>
              {labelFunction ? labelFunction(item) : get(item, labelField)}
            </MenuItem>
        );
      });
    }
    menuItemsList.unshift(empty);
    return menuItemsList;
  };

  const value = get(values, `${name}.${valueField}`) || EMPTY_VALUE

  const configSelect = {
    ...field,
    ...otherProps,
    value: value,
    label: label,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}
               InputLabelProps={{
                 shrink: true,
               }}
    >
      {optionsList()}
    </TextField>
  );
};

export default ObjectSelectWrapper;

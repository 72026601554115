import React, {useEffect, useState} from 'react';

import {
    Container,
    Grid, Tab, Tabs
} from '@material-ui/core';
import {get_from_api, isOk} from "util/api";
import FormContainer from "components/containers/FormContainer";
import OutstandingRequests from "components/fragments/requests/OutstandingRequests";
import CompletedRequests from "components/fragments/requests/CompletedRequests";
import ArchivedRequests from "components/fragments/requests/ArchivedRequests";
import {FormTitle} from "components/typography";
import ReactGA from "react-ga";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}


const RequestTable = () => {

    const [tabValue, setTabValue] = React.useState(0);

    useEffect(()=>{
        ReactGA.pageview('/requests-table');
    },[])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        getRequestsSummary();
    };


    const [requestsSummary, setRequestsSummary] = useState({
        outstanding: 0,
        completed: 0,
        archived: 0
    })

    const getRequestsSummary = async ()=>{
        let response = await get_from_api("/popi-form-api/data-requests/status-summary");
        if(isOk(response.status)){
            setRequestsSummary(response.data)
        }
    }

    useEffect(()=>{
        getRequestsSummary()
    }, [])

    return (
        <Grid container>
            <Grid >
                <Container>
                    <FormContainer >
                        <FormTitle align="left">
                            Data Requests
                        </FormTitle>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Outstanding" icon={<div>{requestsSummary.outstanding}</div>} />
                            <Tab label="Completed" icon={<div>{requestsSummary.completed}</div>} />
                            <Tab label="Archived"  icon={<div>{requestsSummary.archived}</div>} />
                        </Tabs>

                        <TabPanel value={tabValue} index={0} >
                            <OutstandingRequests />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} >
                            <CompletedRequests />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} >
                            <ArchivedRequests />
                        </TabPanel>

                    </FormContainer>
                </Container>
            </Grid>
        </Grid>
    );
};

export default RequestTable;

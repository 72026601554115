import React, {useEffect, useState, useContext} from 'react';

import {
    Button,
    Container,
    Grid
} from '@material-ui/core';
import {get_from_api, isOk } from "util/api";
import FormContainer from "components/containers/FormContainer";
import MUIDataTable from "mui-datatables";
import { Edit, Add} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {SubscriptionContext} from "context/SubscriptionContext";
import {FormTitle} from "components/typography";
import PageLoader from "components/containers/PageLoader";
import {toShortDate} from "util/dates";
import {toSentenceCase} from "util/text";


const ViewUsers = () => {

    const history = useHistory();

    const columns =[
        {
            name: "id",
            options: {
                filter: true,
                display: "excluded"
            }
        },
        {
            name: "firstName",
            label: "Firstname",
        },
        {
            name: "lastName",
            label: "Lastname",
           
        },
        {
            name: "email",
            label: "Email"
        },
        {
            name: "userRole",
            label: "Role",
            options: {
                filter: true,
                sort: false,
                empty: true,
                setCellProps: () => ({ style: { textTransform:'capitalize' }}),
                customBodyRender: (value, tableMeta, updateValue) => (
                    toSentenceCase(value)
                ),
            }
        },
        {
            name: "status",
            label: "Status"
        },
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={()=>{history.push(`/edit-user/${users[rowIndex].id}`)}}
                            startIcon={<Edit />}
                        >
                            Edit
                        </Button>
                    );
                }
            }
        },

    ]

    const [users, setUsers] = useState([]);
    const [formInitialised, setFormInitialised] = useState(false)
    const [canAddUser, setCanAddUser] = useState(false)


    const options = {
        filterType: 'multiselect',
        selectableRows: 'none',
        download: false,
        print: false,
        onRowClick: (rowData)=>{
            history.push(`/edit-user/${rowData[0]}`)
        },
        textLabels: {
            body: {
                noMatch: "No records available"
            }
        }
    };

    const getUsers = async()=>{
        let response = await get_from_api("/popi-form-api/users/");
        if(isOk(response.status)){
            setUsers(response.data);
        }
        setFormInitialised(true)
    }

    const checkCanAddUser = async()=>{
        let response = await get_from_api("/popi-form-api/users/can-add-user");
        if(isOk(response.status) && response.data.success){
            setCanAddUser(true);
        }
    }

    useEffect(()=>{
        checkCanAddUser();
        getUsers()
    }, [])


    if(!formInitialised){
        return <PageLoader />
    }

    return (
        
        <Grid container >
            <Grid >
                <Container>
                    <FormContainer >

                        <FormTitle align="left">
                           Manage Users
                        </FormTitle>


                        <Grid container spacing={2}>
                            <Grid item xs={12} justify={"flex-end"}>

                            {canAddUser &&
                            <Button
                                variant="contained"
                              
                                color="primary"
                                size="small"
                                onClick={()=>{history.push(`/edit-user`)}}
                                startIcon={<Add />}
                            >
                                Add user
                            </Button>
                            }
                    

                            </Grid>

                            <Grid item xs={12}>

                           
                                <MUIDataTable
                                    title={"Users"}
                                    data={users}
                                    columns={columns}
                                    options={options}
                                />
                           
                           
                            </Grid>
                        </Grid>
                    </FormContainer>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ViewUsers;

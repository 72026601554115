export const toSentenceCase = (stringValue) => {
    if(!stringValue) return null;
    return capitalizeFirstLetter(stringValue.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").toLowerCase());
}

 export const  capitalizeFirstLetter = (stringValue) =>{return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

export const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const formatMoney = (money) => {
    return money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
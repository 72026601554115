import React, {useState} from 'react';
import {TextField, IconButton, InputAdornment } from '@material-ui/core';
import {useField} from 'formik';
import {SmallText} from 'components/typography'


const PasswordFieldWrapper = ({name, ...otherProps }) => {
    const [field, meta] = useField(name);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const configField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',

    };

    if (meta && meta.touched && meta.error) {
        configField.error = true;
        configField.helperText = meta.error;
    }

    return (
        <TextField
            {...configField}
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? (
                                <SmallText>HIDE</SmallText>
                            ) : (
                                <SmallText>SHOW</SmallText>
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordFieldWrapper;

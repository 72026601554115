import React, {useContext, useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Grid, Link,
    Typography
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import PasswordField from 'components/form/PasswordField'
import Button from 'components/form/Button';
import CardFormWrapper from "components/containers/CardFormWrapper";
import {FormDescription, FormSection, FormTitle} from "components/typography";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {AuthContext} from "context/authContext";
import {getItem, SESSION_KEYS, setItem, setSessionItem} from "util/cache";
import ChangeTempPassword from "components/fragments/security/ChangeTempPassword";
import VerifyAdminEmail from "components/fragments/security/VerifyAdminEmail";
import Logo from "components/form/Logo";
import ReactGA from "react-ga";
import {useSnackbar} from "notistack";


const INITIAL_FORM_STATE = {
    email: '',
    password: ''
};

const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email.')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});



const Login = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        ReactGA.pageview('/login');
        if(props.message){
            enqueueSnackbar(props.message, {
                variant: 'success',
            })
        }
    },[])

    const [error, setError] = useState('')

    const history = useHistory();
    const authContext = useContext(AuthContext)

    const [userState,setUserState] = useState({
        email: '',
        password: '',
        needsToChangePassword: false,
        needsToConfirmAccount: false
    })

    const showChangePasswordForm = (email,password) => {
        setUserState({email: email, password: password, needsToChangePassword: true, needsToConfirmAccount: false});
    }

    const showVerifyEmailForm = (email,password) => {
        setSessionItem(SESSION_KEYS.EMAIL, email)
        setUserState({email: email, password: password, needsToChangePassword: false, needsToConfirmAccount: true});
    }

    const shouldShowLoginForm = React.useMemo(()=>{
        return !userState.needsToChangePassword && !userState.needsToConfirmAccount
    }, [userState])

    const shouldShowChangePasswordForm = React.useMemo(()=>{
        return userState.needsToChangePassword && !userState.needsToConfirmAccount
    }, [userState])

    const shouldShowVerifyEmailForm = React.useMemo(()=>{
        return !userState.needsToChangePassword && userState.needsToConfirmAccount
    }, [userState])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <CardFormWrapper>

                        {shouldShowLoginForm &&
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async values => {
                                try {
                                    await authContext.signInWithEmail(values.email, values.password)
                                    const firstTime = getItem("firstTime");
                                    if(firstTime === "false"){
                                        history.push('/home')
                                    }else {
                                        setItem("firstTime", true);
                                        history.push('/form')
                                    }

                                } catch (err) {

                                    if (err.code === 'UserNotConfirmedException') {
                                        showVerifyEmailForm(values.email, values.password);
                                    } else if (err.code === 'NewPasswordRequired') {
                                        showChangePasswordForm(values.email, values.password);
                                    } else {
                                        setError(err.message)
                                    }
                                }
                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            <Logo url="/logo.svg" />
                                            POPI Fox
                                        </FormTitle>

                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormSection>
                                            Log in
                                        </FormSection>

                                    </Grid>


                                    <Grid item xs={12}>
                                        <Textfield
                                            name="email"
                                            label="Email"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordField
                                            name="password"
                                            label="Password"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography color="error" variant="body2">
                                            {error}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button>
                                            Log in
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormDescription>
                                            Don't have an account? <Link component={RouterLink}
                                                                         to="/signup">Sign up</Link>
                                        </FormDescription>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormDescription> <Link component={RouterLink}
                                                                         to="/forgotPassword">Forgot Password?</Link>
                                        </FormDescription>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>
                        }

                        {shouldShowChangePasswordForm &&
                            <ChangeTempPassword email={userState.email} password={userState.password} />
                        }

                        {shouldShowVerifyEmailForm &&

                            <VerifyAdminEmail email={userState.email} password={userState.password} />

                        }

                    </CardFormWrapper>
                </Container>
            </Grid>
        </Grid>
    );
};

export default Login;

import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Grid, Link,
} from '@material-ui/core';
import Select from 'components/form/Select';
import ObjectSelect from 'components/form/ObjectSelect';
import Button from 'components/form/Button';
import {FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import FormContainer from "components/containers/FormContainer"
import {get_from_api, isOk, post_to_api} from "util/api";
import PageLoader from "components/containers/PageLoader";
import {useParams} from "react-router";
import {ViewFormField, ViewObjectField} from "components/form/ViewField";
import {toMediumDate} from "util/dates";
import {Link as RouterLink} from "react-router-dom";

const INITIAL_FORM_STATE = {
    logo: '',
    color: '#0068AB',
    subDomain: '',
};

const FORM_VALIDATION = Yup.object().shape({
    dataRequestType : Yup.string()
        .required("Request Type is required"),
    status : Yup.string()
        .required("Status is required"),
});

const ViewRequest = () => {

    let { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [initialFormState, setInitialFormState] = useState(null)
    const [formInitialised, setFormInitialised] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(()=>{
        const getUsers = async () => {
            let response = await get_from_api("/popi-form-api/users/")
            if(isOk(response.status)) {
                setUsers(response.data)
            }
        }
        const getRequest = async () => {
            let response = await get_from_api(`/popi-form-api/data-requests/id/${id}`)
            if(isOk(response.status)) {
                setInitialFormState(response.data)
                setFormInitialised(true)
            }else{
                setInitialFormState(INITIAL_FORM_STATE)
                setFormInitialised(true)
            }
        }
        getUsers();
        getRequest();
    },[id])

    if(!formInitialised){
        return <PageLoader />
    }

    return (
        <Grid container>
            <Grid item >
                <Container>
                    <Link component={RouterLink} to="/requests">Back to all requests</Link>
                    <FormContainer >

                        <Formik
                            initialValues={initialFormState}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {
                                    let response = await post_to_api(values,"/popi-form-api/data-requests/")
                                    if(isOk(response.status)) {
                                        enqueueSnackbar(`Data request updated`, {
                                            variant: 'success',
                                        })
                                        form.setValues(response.data)
                                    }else{
                                        enqueueSnackbar(`An error occurred, Please try again`,{
                                            variant: 'error',
                                        })
                                    }

                                }catch (e){
                                    console.log("Error", e)
                                    enqueueSnackbar(`An error occurred, Please try again`,{
                                        variant: 'error',
                                    })
                                }

                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle align="left">
                                            Edit Request
                                        </FormTitle>


                                    </Grid>

                                    <Grid item xs={12}>
                                        <Select
                                            name={"dataRequestType"}
                                            label={"Data Request Type"}
                                            options={[
                                                {value: "DataRequest",label: "Data request"},
                                                {value: "Deletion",label: "Deletion"},
                                                {value: "Rectify",label: "Rectify"},
                                                {value: "StopProcessing",label: "Stop processing"},
                                                {value: "StopDirectMarketing",label: "Stop direct marketing"},
                                                {value: "ChangeUse",label: "Change use"},
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Select
                                            name={"status"}
                                            label={"Status"}
                                            options={[
                                                {value: "NotStarted",label: "Not started"},
                                                {value: "InProgress",label: "In progress"},
                                                {value: "Completed",label: "Completed"},
                                                {value: "Archived",label: "Archived"},
                                            ]}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <ObjectSelect
                                            name="assignedTo"
                                            label="Assigned to"
                                            valueField="id"
                                            labelFunction={(user)=>(`${user.firstName} ${user.lastName}`)}
                                            disableFunction={(user)=>(user.status !== "Active")}
                                            placeholder="Select user"
                                            options={users}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ViewFormField name="dataSubjectName" label={"Data subject name"} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ViewFormField name="email" label={"Email"} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ViewFormField name="receivedOn" label={"Received on"} displayFunction={toMediumDate} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ViewObjectField name={"data"} exclusions={["email","dataSubjectName", "dataRequestType"]} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                        >
                                            Save
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>

                    </FormContainer>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ViewRequest;

import React from 'react'
import {Grid, makeStyles} from "@material-ui/core";
import {useField} from "formik";
import {toSentenceCase} from "util/text";

let useStyles = makeStyles(theme => ({
    formLabel: {
        color: "#000000",
        fontSize: theme.typography.fontSize,
        fontWeight: "700",
    },formText: {
        fontSize: theme.typography.fontSize,
    },fieldContainer: {
        marginBottom: theme.spacing(2)
    }
}));

export const ViewField = (props)=>{
    let {value,label} = props

    let classes = useStyles();

    return (
        <Grid container direction={"column"}>
            <div className={classes.formLabel}>{label}</div>
            <div className={classes.formText}>{value}</div>
        </Grid>
    )
}

export const ViewFormField = ({
                                  name,
                                  label,
                                  displayFunction,
                              })=>{
    const [field] = useField(name);

    return (
        <ViewField label={label} value={displayFunction ? displayFunction(field.value) : field.value} />
    )
}


export const ViewObjectField = ({
    name,
    exclusions,
}) =>{
    const [field] = useField(name);
    let classes = useStyles();
    return (
        Object.keys(field.value).map((key, index) => {
            if(exclusions.includes(key)){
                return null
            }
            return <div className={classes.fieldContainer}><ViewField value={field.value[key]} label={toSentenceCase(key)} /></div>
        })
    )
}
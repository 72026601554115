import React from 'react'
import {Typography} from "@material-ui/core";

import useStyles from "./styles";

export const SmallText = (props) => (
    <Typography style={{fontSize:'11px'}} >{props.children}</Typography>
)

export const SubSectionTitle = (props) => (
    <Typography variant="h4" >{props.children}</Typography>
)

export const FormTitle = (props) => {
    const classes = useStyles();
    const align = props.align || "center";
    return (
        <div className={classes.formTitle} style={{textAlign: align}}>{props.children}</div>
    )
}

export const FormSubTitle = (props) => {
    const classes = useStyles();
    const align = props.align || "center";
    return (
        <div className={classes.formSubTitle} style={{textAlign: align}}>{props.children}</div>
    )
}

export const FormDescription = (props) => {
    const classes = useStyles();
    const align = props.align || "center";
    return (
        <div className={classes.formDescription} style={{textAlign: align}} >{props.children}</div>
    )
}

export const FormSection = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.formSection}>{props.children}</div>
    )
}

export const FormFieldLabel = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.formLabel}>{props.children}</div>
    )
}

export const FormTitleHelper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.formTitleHelper}>{props.children}</div>
    )
}


export const LinkText = (props) => {
    const classes = useStyles();
    return (
        <span className={classes.linkText}>{props.children}</span>
    )
}

export const Error = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.errorText}>{props.children}</div>
    )
}


export const ChipLabel = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.chip}
             style={{backgroundColor: props.variant.bgColor, color: props.variant.color}}
        >{props.children}</div>
    )
}

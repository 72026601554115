import React, {useEffect, useState} from 'react';

import {
    Button,
} from '@material-ui/core';
import { isOk, post_to_api} from "util/api";
import MUIDataTable from "mui-datatables";
import { Edit} from "@material-ui/icons"
import {useHistory} from "react-router-dom";
import {toShortDate} from "util/dates";
import {toSentenceCase} from "util/text";
import {ChipLabel} from "components/typography";
import {RequestTypesColors, StatusColors} from "util/requestTypes";
import PageLoader from "components/containers/PageLoader";



const RequestsTable = (props) => {

    const history = useHistory();

    const [requests, setRequests] = useState([]);

    const [formInitialised, setFormInitialised] = useState(false)

    const columns =[
        {
            name: "id",
            options: {
                filter: true,
                display: "excluded"
            }
        },
        {
            name: "receivedOn",
            label: "Received On",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    toShortDate(value)
                )
            }
        },
        {
            name: "dataRequestType",
            label: "Type",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <ChipLabel variant={RequestTypesColors[value]}>{toSentenceCase(value)?.toUpperCase()}</ChipLabel>
                )
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,


                customBodyRender: (value, tableMeta, updateValue) => (
                    <ChipLabel variant={StatusColors[value]}>{toSentenceCase(value)?.toUpperCase()}</ChipLabel>
                )
            }
        },
        {
            name: "assignedTo",
            label: "Assigned To",
            options: {
                filter: true,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return value ? `${value.firstName} ${value.lastName}` : "Not yet assigned";
                }
            }
        },
        {
            name: "dataSubjectName",
            label: "Name",
            options: {
                filter: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
            }
        },
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={()=>{history.push(`/vie-request/${requests[rowIndex].id}`)}}
                            startIcon={<Edit />}
                        >
                            Edit
                        </Button>
                    );
                }
            }
        },

    ]



    const options = {
        filterType: 'multiselect',
        selectableRows: 'none',
        download: false,
        print: false,
        onRowClick: (rowData)=>{
            history.push(`/view-request/${rowData[0]}`)
        },
        textLabels: {
            body: {
                noMatch: "No records available"
            }
        }
    };

    const getRequests = async()=>{
        let filter = {
            sortField: "receivedOn",
            sortDirection: "DESC",
            filters: [
                {
                    name: "status",
                    operator: "in",
                    value: props.status,
                    dataType: "DataRequestStatus"
                }
            ]
        }

        let response = await post_to_api(filter,"/popi-form-api/data-requests/find");
        if(isOk(response.status)){
            setRequests(response.data.data);
        }

        setFormInitialised(true)
    }

    useEffect(()=>{
        getRequests()
    }, [])

    if(!formInitialised){
        return <PageLoader />
    }

    return (


        <MUIDataTable
            title={""}
            data={requests}
            columns={columns}
            options={options}
        />


    );
};

export default RequestsTable;

import React, {useState, useEffect, useContext} from "react";
import {Drawer, Grid, IconButton, List} from "@material-ui/core";
import {
    Description as EditFormIcon,
    AssignmentInd as ViewRequestsIcon,
    Group as UsersIcon,
    Settings as SettingsIcon,
    ArrowBack as ArrowBackIcon,
    AccountCircle as UserIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./SidebarLink";


// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "context/LayoutContext";
import {AuthContext, HasAnyRole} from "context/authContext";
import classnames from "classnames";

const structure = [
    { id: 0, type: "divider", roles: ["admin", "dataofficer"] },
    { id: 1, label: "View requests", link: "/requests", icon: <ViewRequestsIcon /> , roles: ["admin", "dataofficer"]},
    { id: 2, label: "Configure form", link: "/form", icon: <EditFormIcon /> , roles: ["admin"]},
    { id: 3, label: "Manage users", link: "/users", icon: <UsersIcon /> , roles: ["admin"]},
    { id: 4, label: "Plans and billing", link: "/plans", icon: <SettingsIcon />, roles: ["admin"]},
    { id: 5, label: "Logout", type: "logout", roles: ["admin", "dataofficer"] },
];

function Sidebar({ location }) {
    const classes = useStyles();
    const theme = useTheme();

    const authContext = useContext(AuthContext)
    const userName = `${authContext.sessionInfo.given_name} ${authContext.sessionInfo.family_name}`

    // global
    let { isSidebarOpened } = useLayoutState();
    let layoutDispatch = useLayoutDispatch();

    // local
    let [isPermanent, setPermanent] = useState(true);

    useEffect(function() {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <div>
            <Grid container item direction="column" className={classes.profileGrid}>
                <div><UserIcon fontSize="large" /></div>
                <div className={classnames(classes.userName, {
                    [classes.textHidden]: !isSidebarOpened,
                })}>{userName}</div>
                <div  className={classnames(classes.profileLink, {
                    [classes.textHidden]: !isSidebarOpened,
                })}><Link style={{textDecoration:'none', color:'#0645AD'}} to='/accounts'>View account settings</Link></div>
            </Grid>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <HasAnyRole roles={link.roles}>
                    <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                    />
                    </HasAnyRole>
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        let windowWidth = window.innerWidth;
        let breakpointWidth = theme.breakpoints.values.md;
        let isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(Sidebar);

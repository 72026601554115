import React, {useContext, useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Modal,
    Grid, InputAdornment, Link, Button as MuiButton, makeStyles, Dialog
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import Button from 'components/form/Button';
import {FormDescription, FormSection, FormTitle, SubSectionTitle} from "components/typography";
import ImageUploader from "components/form/ImageUploader";
import ColorPicker from "components/form/ColorPicker";
import { useSnackbar } from 'notistack';
import FormContainer from "components/containers/FormContainer"
import {get_from_api, isOk, post_to_api} from "util/api";
import PageLoader from "components/containers/PageLoader";
import config from "configs";
import { setItem, getItem } from 'util/cache';
import {useHistory} from "react-router-dom";
import {SubscriptionContext} from "context/SubscriptionContext";
import DummyColorPicker from "components/form/ColorPicker/DummyColorPicker";
import {DEFAULT_COLOR} from "util/constants";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DummyImageUploader from "components/form/ImageUploader/DummyImageUploader";





const useStyles = makeStyles((theme) => ({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    title: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    centered: {
        textAlign: "center",
        width: "100%"
    },
    section: {
        marginBottom: theme.spacing(2)
    },
    listSection: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        textAlign: "center",
    },
    listItem :{
        listStyleType : "circle"
    },
    buttonSection: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const adminDomain = config['DOMAIN']

const INITIAL_FORM_STATE = {
    logo: '',
    color: '#0068AB',
    subDomain: '',
};

const FORM_VALIDATION = Yup.object().shape({
    logo : Yup.string()
    .nullable(true),
    color : Yup.string()
    .nullable(true)
    .test('hexColor', 'Color is not a valid hex color',
    function(value) {
        if(value) {
            let color = value.startsWith('#') ? value : `#${value}`;
            return /^#[0-9A-F]{6}$/i.test(color)
        }
    }),
    subDomain : Yup.string()
    .required("Link address is required"),
    business : Yup.object({
        name: Yup.string()
        .required("Business name is required"),
        website: Yup.string().nullable(true),
        email: Yup.string()
        .email('Invalid email')
        .required("Business email is required"),
    })
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
    
const EditForm = () => {

    function getModalStyle() {
        return {
          width: '45%',
          marginTop: '10%',
          textAlign: "center",
        };
    }

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [initialFormState, setInitialFormState] = useState(null)
    const [formInitialised, setFormInitialised] = useState(false)
    
    const [activeSubdomain, setActiveSubdomain] = useState(null)
    const [open, setOpen] = useState(true);
    const firstTime = getItem("firstTime");
    const [modalStyle] = React.useState(getModalStyle);

  
    const handleCloseModal = () => {
      setOpen(false);
    };

    const gotoEditForm = () => {
        handleCloseModal();
        setItem("firstTime", false);
    }
    const [lastValidatedSubdomain, setLastValidatedSubdomain] = useState(null)

    const { onFreePlan } = useContext(SubscriptionContext)

    const isOnFreePlan = onFreePlan ? onFreePlan() : false;

    const history = useHistory();
    const handleOpenPlansPage = ()=>{
        history.push("/plans")
    }
    const [dialogConfig, setDialogConfig] = useState({
        open: false,
        message: "The ability to add a custom logo is available with our premium plans, starting at only R200/mo.",
        cancelBtnMessage: "No thanks, I’ll use the default logo"
    });

    const handleClose =  () => {
        setDialogConfig({
            open: false,
            message: '',
            cancelBtnMessage: ''
        });
    };

    const showCustomLogoMessage = ()=>{
        setDialogConfig({
            open: true,
            message: "The ability to add a custom logo is available with our premium plans, starting at only R200/mo.",
            cancelBtnMessage: "No thanks, I’ll use the default logo"
        })
    }

    const showCustomColorMessage = ()=>{
        setDialogConfig({
            open: true,
            message: "The ability to add a custom form colour is available with our premium plans, starting at only R200/mo.",
            cancelBtnMessage: "No thanks, I’ll use the default colour"
        })
    }

    const validate = async (values )=> {
        const errors = {};
        if(values.subDomain){
            let response = await get_from_api(`/popi-form-api/data-form/subdomainAvailable/${values.subDomain}`);
            if(!isOk(response.status) || !response.data?.isAvailable){
                errors.subDomain = `Custom link "${values.subDomain}" is already taken`
            }
        }
        return errors;
    }
    
    const getFormData = async () => {
        let response = await get_from_api("/popi-form-api/data-form/")
        if(isOk(response.status)) {
            let form = response.data
            if(!form.color){
                form.color = DEFAULT_COLOR;
            }
            setInitialFormState(form)
            if(form.subDomain) {
                setActiveSubdomain(form.subDomain)
            }
            setFormInitialised(true)
        }else{
            setInitialFormState(INITIAL_FORM_STATE)
            setFormInitialised(true)
        }
    }

    const previewForm = ()=>{
        window.open(`https://${activeSubdomain}.${adminDomain}`,"_blank")
    }

    const copyLink = ()=>{
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = `https://${activeSubdomain}.${adminDomain}`;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        enqueueSnackbar(`Link copied`, {
            variant: 'success',
        })
    }

    useEffect(()=>{
       getFormData();
    },[])

    if(!formInitialised){
        return <PageLoader />
    }



    return (
        <Grid container justifyContent="flex-start">
            <Grid item >
            { firstTime == "true" &&  
                <Grid xs={4} >
                        <Modal
                            open={open}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >

                            <Container xs={4} style={modalStyle}>
                                <FormContainer xs={4}>
                                    <h2 id="modal-title">
                                        Welcome to POPI Fox
                                    </h2 >
                                    <p id="modal-description">
                                        Thanks for signing up for POPI Fox! 
                                    </p>
                                    <p>
                                        You’ll get to try our Small Business features free for 7 days, after which you’ll be placed on the Free plan.
                                    </p>                                        First up, let’s customise the form that your users will see. When you’re done, you can copy the link and add it to your website or app.
                                <Grid item>
                                    <p>
                                        <MuiButton variant="contained" color="primary" onClick={gotoEditForm}>
                                            Configure my form
                                        </MuiButton>
                                    </p>
                                </Grid>
                                </FormContainer>
                            </Container>
                    </Modal>
                </Grid>}
                <Container maxWidth="md" >
                    <FormContainer >

                        <Formik
                            initialValues={initialFormState}
                            validationSchema={FORM_VALIDATION}
                            validate={validate}
                            onSubmit={async (values, form) => {
                                try {
                                    let response = await post_to_api(values,"/popi-form-api/data-form/")
                                    if(isOk(response.status)) {

                                        setActiveSubdomain(values.subDomain)

                                        enqueueSnackbar(`Form details updated`, {
                                            variant: 'success',
                                        })
                                    }else{
                                        enqueueSnackbar(`An error occurred, Please try again`,{
                                            variant: 'error',
                                        })
                                    }

                                }catch (e){
                                    console.log("Error", e)
                                    enqueueSnackbar(`An error occurred, Please try again`,{
                                        variant: 'error',
                                    })
                                }

                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle align="left">
                                            Configure Request Form
                                        </FormTitle>

                                        <FormDescription align="left">
                                            Configure the form you will use to capture data requests
                                        </FormDescription>


                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormSection>Your Logo</FormSection>
                                        {!isOnFreePlan &&
                                        <ImageUploader
                                            name="logo"
                                            canRemove={true}
                                        />}

                                        {isOnFreePlan &&
                                            <DummyImageUploader onClick={showCustomLogoMessage}/>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            name="business.name"
                                            label="Business Name"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            name="business.website"
                                            label="Website"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            name="business.email"
                                            label="Business Email"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {!isOnFreePlan &&
                                            <ColorPicker
                                                name="color"
                                                label="Custom Form Color (hex code)"
                                            />
                                        }

                                        {isOnFreePlan &&
                                            <DummyColorPicker value={DEFAULT_COLOR} onClick={showCustomColorMessage} onKeyPress={showCustomColorMessage}/>
                                        }
                                    </Grid>


                                    <Grid item  xs={12}>
                                        <Textfield
                                            name="subDomain"
                                            label="Custom Form Link"
                                            id="subDomain"
                                            helperText="This is the link you will provide to your customers to access your form"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <div>.{adminDomain} </div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        {activeSubdomain &&
                                            <Grid item container xs={12}>
                                                <Grid item>
                                                    <Button variant='outlined' callback={previewForm} >
                                                        Preview form
                                                    </Button>
                                                </Grid>

                                                <Grid item>
                                                    <div className={classes.wrapper}>
                                                        <MuiButton variant='outlined' color="primary" onClick={copyLink} >
                                                            Copy link
                                                        </MuiButton>
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        }

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                        >
                                            Save
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>

                    </FormContainer>
                </Container>



            </Grid>

            <Dialog  onClose={handleClose} aria-labelledby="premium-feature-title" open={dialogConfig.open} >

                <DialogTitle id="premium-feature-title" onClose={handleClose} ><div className={classes.title}>This is a premium feature</div></DialogTitle>
                <DialogContent>
                    <Grid direction="column"
                          justifyContent="center"
                          alignItems="center">
                        <div className={classes.section}>
                            <div className={classes.centered}>{dialogConfig.message}</div>
                        </div>

                        <div className={classes.buttonSection}>
                            <MuiButton
                                onClick={handleOpenPlansPage}
                                variant="contained"
                                color="primary"
                            >View available plans</MuiButton>
                        </div>

                        <div className={classes.buttonSection}>
                            <MuiButton
                                onClick={handleClose}
                                variant={"outlined"}
                                color="primary"
                            >{dialogConfig.cancelBtnMessage}</MuiButton>
                        </div>

                    </Grid>
                </DialogContent>

            </Dialog>
        </Grid>
    );
};

export default EditForm;

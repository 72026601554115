import React, {useContext, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Link, TextField, Button as MuiButton
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import Button from 'components/form/Button';
import {Error, FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import {isValidEmail} from "util/text";
import {isOk, post_to_api} from "util/api";
import {getSessionItem, SESSION_KEYS, setSessionItem} from "util/cache";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const INITIAL_FORM_STATE = {
    code: ''
};

const FORM_VALIDATION = Yup.object().shape({
    code: Yup.string()
        .required("Verification code is required"),
});

const VerifyEmail = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const authContext = useContext(AuthContext)

    const [emailDialogueOpen, setEmailDialogueOpen] = useState(false);

    const handleClickEmailDialogueOpen = (event) => {
        event.preventDefault();
        setEmailDialogueOpen(true);
    };

    const handleCloseEmailDialogue = () => {
        setEmailDialogueOpen(false);
        setChangingEmail(false);
    };

    const [newEmail, setNewEmail] = React.useState('');
    const [changeEmailError, setChangeEmailError] = React.useState(null);
    const [changingEmail, setChangingEmail] = React.useState(false);
    const handleNewEmailChange = (event) => {
        setNewEmail(event.target.value);
    };

    const changeEmail = async ()=>{
        if(!isValidEmail(newEmail)){
            setChangeEmailError("Email is not valid");
            return;
        }
        setChangeEmailError(null);
        setChangingEmail(true);
        try {
            let response = await post_to_api({
                username: username,
                email: newEmail
            }, "/popi-form-api/users/change-signup-email", false);
            if(isOk(response.status)) {
                if(response.data.success) {
                    enqueueSnackbar(`Email updated, please check your email for code`, {
                        variant: 'success',
                    })
                    setSessionItem(SESSION_KEYS.EMAIL, newEmail)
                    setNewEmail(null)
                    handleCloseEmailDialogue();
                }else {
                    setChangeEmailError(response.data.message);
                }

            }else{
                enqueueSnackbar(`Failed to change email, please try again`,{
                    variant: 'error',
                })
            }
        }catch (e) {
            console.error("Error changing email", e)
            enqueueSnackbar(`Failed to change email, please try again`,{
                variant: 'error',
            })
        }
    }

    const resendCode = async (event)=> {
        event.preventDefault();
        try {
            await authContext.sendCode(email)
            enqueueSnackbar(`Verification code resent, check your email`,{
                variant: 'success',
            })
        }catch (e){
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }
    }


    const email = getSessionItem(SESSION_KEYS.EMAIL)
    const username = getSessionItem(SESSION_KEYS.USERNAME)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>

                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {
                                    await authContext.verifyCode(email, values.code)
                                    enqueueSnackbar(`Email verified, Please login`,{
                                        variant: 'success',
                                    })
                                    history.push("/login")
                                }catch (e){
                                    enqueueSnackbar(`Invalid code captured, Please try again`,{
                                        variant: 'error',
                                    })
                                    form.resetForm()
                                }

                            }}
                        >
                            <Form autocomplete="off">

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            We’ve sent you a code via email
                                        </FormTitle>

                                        <FormDescription>
                                            To confirm your identity, we’ve sent a 6 digit code via email to <strong>{email}</strong>. Please enter it below
                                        </FormDescription>

                                        <FormDescription>
                                            Didn’t receive it? <Link component="button" style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} onClick={resendCode}>Send it again</Link>
                                        </FormDescription>

                                        <FormDescription>
                                            Entered your email address incorrectly? <Link style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={handleClickEmailDialogueOpen}>Change it</Link>
                                        </FormDescription>

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            autocomplete="off"
                                            name="code"
                                            label="Verification code"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button>
                                            Submit
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>


                        <Dialog open={emailDialogueOpen} onClose={handleCloseEmailDialogue} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change email</DialogTitle>
                            <DialogContent>
                                <div style={{marginBottom: '14px'}}>
                                    Please enter your email address below.
                                </div>
                                <TextField
                                    autoFocus
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    value={newEmail}
                                    onChange={handleNewEmailChange}
                                    variant = "outlined"
                                    required
                                    fullWidth
                                />
                                {changeEmailError && <Error>{changeEmailError}</Error>}
                            </DialogContent>
                            <DialogActions>
                                <MuiButton onClick={handleCloseEmailDialogue} color="primary" disabled={changingEmail}>
                                    Cancel
                                </MuiButton>
                                {!changingEmail && <MuiButton onClick={changeEmail} color="primary">
                                    Change Email
                                </MuiButton>}
                                {changingEmail && <CircularProgress size={24} color="primary" />}
                            </DialogActions>
                        </Dialog>

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default VerifyEmail;

import {makeStyles} from "@material-ui/core/styles";
import React from "react";


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

const CardFormWrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.formWrapper}>{props.children}</div>
    )
}

export default CardFormWrapper
import { createMuiTheme } from '@material-ui/core';
import typography from './typography';
import MuiInputBase from "./MuiInputBase";

const primary = "#0068AB";
const secondary = "#0068AB";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

export const themeProps = {
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    info: {
      main: info,
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
        "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
        "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
        "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  typography,
  overrides: {
    MuiInputBase,
  }
}

const theme = createMuiTheme(themeProps);

export default theme;

import {DEFAULT_COLOR} from "util/constants";
import {getSession} from "libs/cognito";

export  const isObject = (item)=>{
    return typeof item === 'object' && item !== null
}

export const formatColor = (color) =>{
    if(color && !color.startsWith('#')){
        return `#${color}`
    }else if(!color){
        return DEFAULT_COLOR
    }
    return color
}

export const getAccessToken = async ()=>{
    let accessToken = window.localStorage.getItem("accessToken")
    let expiryAt = window.localStorage.getItem("tokenExpAt")
    if(accessToken && expiryAt){
        if(new Date().getTime() > new Date(parseInt(expiryAt)).getTime()){
            await getSession();
            accessToken = window.localStorage.getItem("accessToken")
        }
    }
    return accessToken;

}
import React from "react";
import {ListItem, ListItemIcon, ListItemText, makeStyles, Typography} from "@material-ui/core";
import { Done, FiberManualRecord } from "@material-ui/icons";
import PropTypes from "prop-types";
import { isCriteriaMet } from "./utils";
import classNames from "classnames";


const useStyles = makeStyles(theme => ({
  criteriaTitle: {
    fontWeight: "300",
    fontSize: "15px",
  },
  criteriaMet: {
    color: "#000",
  },
  criteriaNotMet: {
    color: theme.palette.text.disabled,
  },
}));

const CriteriaTitle = (props) => {
  let classes = useStyles();
  return (
  <Typography className={classNames(classes.criteriaTitle, props.criteriaMet ? classes.criteriaMet : classes.criteriaNotMet)}>{props.children}</Typography>
  )
}

export const CHARACTER_TYPE = {
  NUMBER: "number",
  LOWERCASE: "lower_case",
  UPPERCASE: "upper_case",
  ANY: "any",
};

const PasswordCriteriaItem = (props) => {
  let { password, characterType, numOfCharactersRequired } = props;

  const criteriaMet = React.useMemo(
    () => isCriteriaMet(characterType, numOfCharactersRequired, password),
    [password, characterType, numOfCharactersRequired ]
  );

  const title = React.useMemo(() => {
    let chars = numOfCharactersRequired || 1;
    switch (characterType) {
      case CHARACTER_TYPE.NUMBER:
        return `${chars} ${chars === 1 ? "number" : "numbers"}`;
      case CHARACTER_TYPE.LOWERCASE:
        return `${chars} lowercase ${chars === 1 ? "letter" : "letters"}`;
      case CHARACTER_TYPE.UPPERCASE:
        return `${chars} uppercase ${chars === 1 ? "letter" : "letters"}`;
      default:
        return `${chars} ${chars === 1 ? "character" : "characters"}`;
    }
  }, [numOfCharactersRequired, characterType]);

  return (
    <ListItem dense disablePadding style={{ height: "30px" }}>
      <ListItemIcon style={{ padding: 5, minWidth: 20 }}>
        {criteriaMet ? (
          <Done color="primary" style={{ fontSize: "16px" }} />
        ) : (
          <FiberManualRecord color="disabled" style={{ fontSize: "16px" }} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <CriteriaTitle criteriaMet={criteriaMet}>{title}</CriteriaTitle>
        }
      />
    </ListItem>
  );
};

PasswordCriteriaItem.propTypes = {
  numOfCharactersRequired: PropTypes.number,
  characterType: PropTypes.oneOf(["any", "upper_case", "lower_case", "number"]),
  password: PropTypes.string,
};

PasswordCriteriaItem.defaultProps = {
  numOfCharactersRequired: 1,
};

export default PasswordCriteriaItem;

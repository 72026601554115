import React, {useContext} from 'react';
import Textfield from "components/form/Textfield";
import TextArea from "components/form/TextArea";
import Select from "components/form/Select";
import RadioButtonGroup from "components/form/RadioButtonGroup";
import {Grid} from "@material-ui/core";
import {CompanyContext} from "context/CompanyContext";
import {dynamicTemplate} from "util/template";

const DynamicForm = ({config}) => {

    const companyContext = useContext(CompanyContext)

    const popiForm = companyContext.popiForm

    const templateVars = {...popiForm}

    const processLabel = (label) => {
        return dynamicTemplate(label,templateVars)
    }

    const processOptions = (options)=>{
        return options.map(option => {
            return {
                value: option.value,
                label: processLabel(option.label)
            }
        })
    }

    const builder = (individualConfig, index) => {

        let label = individualConfig.label ? processLabel(individualConfig.label) : individualConfig.label;

        let valueOptions = individualConfig.valueOptions ? processOptions(individualConfig.valueOptions) : individualConfig.valueOptions;

        switch (individualConfig.type) {
            case 'text':
                return (

                        <Grid item xs={12} key={index}>
                            <Textfield
                                name={individualConfig.field}
                                label={label}
                            />
                        </Grid>

                );
            case 'textArea':
                return (

                        <Grid item xs={12} key={index} >
                            <TextArea
                                name={individualConfig.field}
                                label={label}
                            />
                        </Grid>

                );
            case 'select':
                return (

                        <Grid item xs={12} key={index}>
                            <Select
                                name={individualConfig.field}
                                label={label}
                                options={valueOptions}
                            />
                        </Grid>

                );

            case 'radioButtonGroup':
                return (

                        <Grid item xs={12} key={index}>
                            <RadioButtonGroup
                                name={individualConfig.field}
                                label={label}
                                options={valueOptions}
                            />
                        </Grid>

                );
            case 'number':
                return (

                        <Grid item xs={12} key={index} >
                            <Textfield
                                name={individualConfig.field}
                                label={label}
                            />
                        </Grid>

                )
            case 'array':
                return (
                    <DynamicForm config={individualConfig.children || []} />
                );
            default:
                return <div>Unsupported field</div>
        }
    }

    return (
        <>
            {config.map((c,index) => {
                return builder(c,index);
            })}
        </>
    );
}

export default DynamicForm
import React, {useContext} from 'react';
import {
    Button,
} from '@material-ui/core';
import {isOk, post_to_api} from "util/api";
import {useSnackbar} from "notistack";
import {SubscriptionContext} from "context/SubscriptionContext";
import {toMediumDate} from "util/dates";


const FreePlanButton = (props) => {

    let {plan, callback, planChange} = props

    const {getSubscription} = useContext(SubscriptionContext)

    const { enqueueSnackbar } = useSnackbar();

    const handleClick = async ()=>{
        let response = await post_to_api(null,`/popi-form-api/subscriptions/select-free-plan/${plan.id}`)
        if(isOk(response.status) && response.data.success) {
            enqueueSnackbar(`${plan.name} activated`, {
                variant: 'success',
            })
            getSubscription();
        }else{
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }

        if(callback){
            callback();
        }
    }

    if(planChange && planChange.plan && planChange.plan?.id === plan?.id){
        return (
            <Button fullWidth variant="outlined" color="primary"
            >
                Active from {toMediumDate(planChange.changeDate)}
            </Button>
        )
    }

    return (
        <>
            <Button fullWidth={props.fullWidth || true} variant={props.variant || "contained"} color="primary"
                    onClick={handleClick}>
                {props.text || "Change to this plan"}
            </Button>
        </>

    );
}

export default FreePlanButton;
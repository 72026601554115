import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useLocation} from "react-router-dom";
import PageLoader from "components/containers/PageLoader";
import {isOk, post_to_api} from "util/api";
import {Grid, Link} from "@material-ui/core";
import {FormDescription} from "components/typography";
import {makeStyles} from "@material-ui/core/styles";
import Login from "pages/login";

const useStyles = makeStyles((theme) => ({
    alertMessage: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px solid rgb(185,74,72)",
        color: "rgb(185,74,72)",
        backgroundColor: "rgb(242,222,222)",
        fontSize: "16px",
        lineHeight : "21px",
    },
}));

const Confirm = () => {
    const classes = useStyles();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    const [tokenStatus, setTokenStatus] = useState({verified: false, valid: false, expired: false})

    const verifyToken = async ()=>{
        try {
            const response = await post_to_api({token: token}, `/popi-form-api/users/confirm-signup`)
            if (isOk(response.status)) {
                setTokenStatus({verified: true, valid: response.data.valid, expired: response.data.expired})
            } else {
                setTokenStatus({...tokenStatus, verified: true, valid: false})
            }
        }catch (e) {
            console.log("Error verifying token", e)
            setTokenStatus({...tokenStatus, verified: true, valid: false})
        }
    }

    useEffect(()=>{
        verifyToken();
    }, [])

    if(!tokenStatus.verified){
        return <PageLoader />
    }

    if(tokenStatus.valid){
        return <Login message="Email verified. Please log in." />
    }

    return (
        <div>
            {tokenStatus.expired && <div className={classes.alertMessage}>Your verification link has expired. Login to get a new verification link</div>}
            {!tokenStatus.expired && <div className={classes.alertMessage}>Your verification link is not valid</div>}
            <div style={{marginTop: '20px'}} >&nbsp;</div>
            <Grid item xs={12}>
                <FormDescription>
                    Don't have an account? <Link component={RouterLink}
                                                 to="/signup">Sign up</Link>
                </FormDescription>
            </Grid>

            <Grid item xs={12}>
                <FormDescription>
                    Already have an account? <Link component={RouterLink}
                                                 to="/login">Login</Link>
                </FormDescription>
            </Grid>
        </div>
    )
}

export default Confirm;

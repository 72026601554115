import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Container,
    Grid,
    Link
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import PasswordField from 'components/form/PasswordField'
import Checkbox from 'components/form/Checkbox'
import Button from 'components/form/Button';
import PasswordCriteriaList from "components/form/PasswordCriteriaList";
import {FormDescription, FormSubTitle, FormTitle, FormTitleHelper} from "components/typography";
import {Link as RouterLink} from "react-router-dom";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CardFormWrapper from "components/containers/CardFormWrapper";
import {useSnackbar} from "notistack";
import {isOk, post_to_api} from "util/api";
import {removeItem, SESSION_KEYS, setItem, setSessionItem} from "util/cache";
import VerifyAdminEmail from "components/fragments/security/VerifyAdminEmail";
import Logo from "components/form/Logo";
import ReactGA from "react-ga"


const INITIAL_FORM_STATE = {
    firstName: '',
    lastName: '',
    business: '',
    email: '',
    password: '',
    passwordValid: false
};

const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string()
        .required("Firstname is required"),
    lastName: Yup.string()
        .required("Lastname is required"),
    business: Yup.string()
        .required("Business name is required"),
    email: Yup.string()
        .email('Invalid email.')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
    passwordValid: Yup.boolean()
        .oneOf([true], 'Password does not meet criteria.')
        .required('Password does not meet criteria.'),
    termsOfService: Yup.boolean()
        .oneOf([true], 'The terms and conditions must be accepted.')
        .required('The terms and conditions must be accepted.'),
});

const Signup = () => {

    useEffect(()=>{
        ReactGA.pageview('/signup');
    },[])

    const { enqueueSnackbar } = useSnackbar();

    const [userState,setUserState] = useState({
        email: '',
        password: '',
        needsToConfirmAccount: false
    })

    const showVerifyEmailForm = (email,password) => {
        setSessionItem(SESSION_KEYS.EMAIL, email)
        setUserState({email: email, password: password, needsToConfirmAccount: true});
    }

    const shouldShowSignupForm = React.useMemo(()=>{
        return !userState.needsToConfirmAccount
    }, [userState])

    const shouldShowVerifyEmailForm = React.useMemo(()=>{
        return userState.needsToConfirmAccount
    }, [userState])


    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <CardFormWrapper>

                        {shouldShowSignupForm && <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {

                                try {

                                    let response = await post_to_api(values, "/popi-form-api/users/sign-up", false);
                                    if (isOk(response.status)) {
                                        removeItem("firstTime");
                                        setSessionItem(SESSION_KEYS.USERNAME, response.data.username);
                                        showVerifyEmailForm(values.email, values.password);
                                    } else {
                                        enqueueSnackbar(response?.data?.message ? response.data.message : `Signup failed, please try again`, {
                                            variant: 'error',
                                        })
                                    }
                                } catch (e) {
                                    console.error("Error signing up", e)
                                    enqueueSnackbar(`Signup failed, please try again`, {
                                        variant: 'error',
                                    })
                                }
                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            <Logo url="/logo.svg" />
                                            POPI Fox
                                        </FormTitle>
                                        <FormSubTitle>
                                            Sign up for free and get set up in minutes
                                        </FormSubTitle>

                                        {/* <FormDescription>
                                            Try out POPI Fox free for 7 days, no upfront payment required
                                        </FormDescription> */}
                                        <FormTitleHelper>
                                            Already have an account? <Link component={RouterLink}
                                                                           to="/login">Login</Link>
                                        </FormTitleHelper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            name="business"
                                            label="Organisation name"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Textfield
                                            name="website"
                                            label="Organisation website"
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <Textfield
                                            name="firstName"
                                            label="Firstname"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Textfield
                                            name="lastName"
                                            label="Lastname"
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Textfield
                                            name="email"
                                            label="Email"
                                            helperText="We will send you an email to validate that you own this address"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordField
                                            name="password"
                                            label="Password"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordCriteriaList passwordField="password"
                                                              passwordValidField="passwordValid"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Checkbox
                                            name="termsOfService"
                                            color="primary"
                                            label={<div>I consent to provide this information so that POPI Fox can
                                                create and configure my account, including the use of cookies to persist
                                                my data. Read our full privacy policy <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" component={RouterLink}
                                                                                            href="https://www.popifox.co.za/privacy-policy">here</a>.
                                            </div>}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            disableOnInvalid={true}

                                        >
                                            Create my account
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormDescription>
                                            <Grid container alignItems="center" justify="center">
                                                <LockOutlinedIcon htmlColor="#888"/>
                                                <div>Your information will be processed securely & privately</div>
                                            </Grid>

                                        </FormDescription>

                                        <FormDescription>
                                            Read our <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" component={RouterLink} href="https://www.popifox.co.za/privacy-policy">Privacy Policy</a> and <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" component={RouterLink} href="https://www.popifox.co.za/terms-of-service">Terms of Service</a>.
                                        </FormDescription>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>
                        }

                        {shouldShowVerifyEmailForm &&

                            <VerifyAdminEmail email={userState.email} password={userState.password} />

                        }

                    </CardFormWrapper>
                </Container>
            </Grid>
        </Grid>
    );
};

export default Signup;

import React from 'react';
import {useField, useFormikContext} from 'formik';

import {IconButton, InputAdornment, Popover, TextField} from "@material-ui/core";
import StopIcon from '@material-ui/icons/Stop';
import { HexColorPicker } from "react-colorful";


const CustomColorPicker = ({
                            name,
                            ...otherProps
                        }) => {

    const { setFieldValue } = useFormikContext();
    const [field, mata] = useField(name)

    const value = field.value ? field.value.startsWith('#') ? field.value.substr(1) : field.value : field.value

    const configTextfield = {
        ...field,
        ...otherProps,
        value: value,
        fullWidth: true,
        variant: 'outlined'
    };

    if (mata && mata.touched && mata.error) {
        configTextfield.error = true;
        configTextfield.helperText = mata.error;
    }

    const color = "#"+ value

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setColor = (value) =>{
        setFieldValue(name, value);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
        <TextField
            {...configTextfield}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <div>#</div>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" variant="filled" onClick={handleClick} >
                        <IconButton
                        >
                           <StopIcon htmlColor={color} fontSize="large"/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />

    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <div style={{padding: '8px'}}> <HexColorPicker color={color} onChange={setColor} /></div>
    </Popover>
            </>
    );
};

export default CustomColorPicker;
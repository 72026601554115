export const SubscriptionPlans = {

    Free: "Free",
    SmallBusiness: "Small business",
    Enterprise: "Enterprise"

}

export const SubscriptionStatus = {

    Active: "Active",
    InActive: "InActive",
    Trial: "Trial",
    TrialEnded: "TrialEnded"

}
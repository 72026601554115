import * as yup from 'yup';
import set from "lodash.set"
export function createYupSchema(schema, config) {
    const { field, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    if(validations) {
        validations.forEach((validation) => {
            const {params, type} = validation;
            if (!validator[type]) {
                return;
            }
            validator = validator[type](...params);
        });
    }
    if (field.indexOf('.') !== -1) {
        // nested fields are not covered in this example but are eash to handle tough
    } else {
        schema[field] = validator;
    }

    return schema;
}

export const getYupSchemaFromMetaData = (
    metadata,
    additionalValidations,
    forceRemove
) => {
    const yepSchema = metadata.reduce(createYupSchema, {});
    const mergedSchema = {
        ...yepSchema,
        ...additionalValidations,
    };

    forceRemove.forEach((field) => {
        delete mergedSchema[field];
    });

    const validateSchema = yup.object().shape(mergedSchema);

    return validateSchema;
};

export const getDefaultInitValues = (fields) =>{
    let initValues = {}
    fields.forEach(function(field) {
        set(initValues,field.field,'')
    });
    return initValues
}
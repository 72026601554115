import {makeStyles} from "@material-ui/core/styles";
import React from "react";


const useStyles = makeStyles((theme) => ({
    formWrapper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        backgroundColor: '#FFFFFF'
    },
}));

const FormContainer = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.formWrapper}>{props.children}</div>
    )
}

export default FormContainer
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    addLogo: {
        fontSize: "20px",
        marginLeft: "8px",
    },
    selectFile: {
        fontSize: "16px",
        textAlign: "center",
        marginBottom: "20px"
    },
    fileFormat: {
        textAlign: "center",
        fontSize: "14px",
    },
    highlight: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    button: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: "16px",
    },
    fileUpload: {
        padding: "20px",
        borderRadius: "16px",
        border: `1px dashed ${theme.palette.primary.main}`,
        cursor: "pointer",
        backgroundColor: "#eee",
        minHeight: "140px",
    },
    dropzone: {
        cursor: "pointer",
        "&:focus": {
            outline: "0",
        },
    },
    image: {
        width: "auto",
        maxWidth: "290px",
        maxHeight: "150px",
        height: "auto",
        marginBottom: "16px",
    },
    imageContainer: {
        width: "auto",
        maxWidth: "310px",
        padding: "8px",
        cursor: "pointer",
        border: "1px solid #fff",
        "& .action-btn": {
            visibility: "hidden",
        },
        "&:hover": {
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            border: "1px dashed #0068AB",
            borderRadius: "16px",
            "& .action-btn": {
                visibility: "visible",
                textAlign: "left",
            },
        },
    },
    uploading: {
        marginTop: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#4E4C6A",
    },
    alert: {
        marginTop: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main
    }
}));


const DummyImageUploader = (props) => {

    const classes = useStyles();

    return (
        <React.Fragment>


                            <div className={classes.dropzone} onClick={props.onClick}>

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="center"
                                    justify="space-evenly"
                                    className={classes.fileUpload}
                                >

                                    <Grid className={classes.selectFile}>
                                        <div className={classes.highlight}>Select a file</div> or
                                        drop your image here.
                                    </Grid>

                                        <Grid
                                            direction="column"
                                            container
                                            item
                                            className={classes.fileFormat}
                                        >
                                            <div>Maximum 10MB in size.</div>
                                            <div>JPG, PNG, or SVG formats.</div>
                                        </Grid>

                                </Grid>
                            </div>


        </React.Fragment>
    );
};

DummyImageUploader.propTypes = {
    onClick: PropTypes.func
};

export default DummyImageUploader;

export const RequestTypesColors = {

    Deletion: {color:'#0068AB',bgColor: '#0068AB10'},
    Transfer: {color:'#0068AB',bgColor: '#0068AB10'} ,
    UsageInfo: {color:'#0068AB',bgColor: '#0068AB10'},
    Rectify: {color:'#0068AB',bgColor: '#0068AB10'},
    StopProcessing: {color:'#0068AB',bgColor: '#0068AB10'},
    ChangeUse: {color:'#0068AB',bgColor: '#0068AB10'},
    DataRequest:  {color:'#0068AB',bgColor: '#0068AB10'},
    StopDirectMarketing: {color:'#0068AB',bgColor: '#0068AB10'},

}

export const StatusColors = {

    NotStarted: {color:'#fff',bgColor: '#0068AB'},
    InProgress: {color:'#fff',bgColor: '#16CF98'} ,
    Completed: {color:'#fff',bgColor: '#16CF98'},
    Archived: {color:'#fff',bgColor: '#0068AB'},

}
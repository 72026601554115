import React, {useContext} from "react";
import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import {themeProps} from "themes";
import {SnackbarProvider} from "notistack";

import FormLayout from "layouts/FormLayout";
import {HashRouter as Router} from "react-router-dom";
import PageLoader from "components/containers/PageLoader";
import {CompanyContext} from "context/CompanyContext";

const FormApp = () => {

    const companyContext = useContext(CompanyContext)

    const popiForm = companyContext.popiForm
    const formInitialised = companyContext.formInitialised


    const theme = React.useMemo(
        () => {
            let formTheme = {...themeProps}
            if(popiForm){
                formTheme.palette.primary.main = popiForm.color;
            }
            return createMuiTheme(formTheme)
        }, [popiForm])


    if(!formInitialised){
        return  <PageLoader />
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                    <Router>
                        <FormLayout popiForm={popiForm}/>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </>
    )

}

export default FormApp;

import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  formTitle: {
    color: "#000000",
    fontSize: "20px",
    lineHeight : "26px",
    fontWeight: "700",
    marginBottom: theme.spacing(1)
  },
  formSubTitle: {
    color: "#000000",
    fontSize: "18px",
    lineHeight : "22px",
    fontWeight: "700",
    marginBottom: theme.spacing(1)
  },
  formDescription: {
    color: "#000000",
    fontSize: "16px",
    lineHeight : "21px",
    fontWeight: "400",
    textAlign: "center",
    marginBottom: theme.spacing(1)
  },
  formSection: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: theme.spacing(1)
  },
  formLabel: {
    color: "#000000",
    fontSize: theme.typography.fontSize,
    fontWeight: "700",
  },
  formTitleHelper: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight : "18px",
    textAlign: "center",
    marginBottom: theme.spacing(1)
  },
  linkText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    cursor: "pointer"
  },
  errorText: {
    color: theme.palette.error.main,
  },
  chip: {
    padding: "5px 10px",
    borderRadius: "50px",
    display: "inline-flex",
    margin: "5px"
  },
  successMessage: {
    padding: "16px",
    backgroundColor: "#d4edda",
    border: "1px solid #c3e6cb",
    color: "#155724"
  }
}));

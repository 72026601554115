import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    Card, CardActions, CardContent, CardHeader,
    Container,
    Grid, Typography,
} from '@material-ui/core';
import {FormSection, FormTitle} from "components/typography";
import FormContainer from "components/containers/FormContainer";


import {makeStyles} from "@material-ui/core/styles";


import {get_from_api, isOk} from "util/api";
import PageLoader from "components/containers/PageLoader";
import {toMediumDate} from "util/dates";
import {formatMoney} from "util/text";
import GetPlanButton from "components/form/GetPlanButton";
import {SubscriptionContext} from "context/SubscriptionContext";


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor: '#0068AB10',
        color: '#0068AB'
    },
    cardHeaderCurrent: {
        backgroundColor: '#0068AB',
        color: '#fff'
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    price: {
        fontSize: "22px",
        fontWeight: "bold",
        color: '#000000'
    },
    perMonth: {
        fontSize: "16px",
        color: '#00000070'
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    description: {
        marginBottom: "16px"
    }
}));

const features = {
    "Free": [
        "Unlimited requests",
        "1 internal user",
        "",
        "",
        "",
        ""
    ],
    "Small business": [
        "Unlimited requests",
        "5 internal users",
        "Custom branding",
        "Automated reminders",
        "Monthly compliance report",
    ],
    "Enterprise": [
        "Everything in Small Business plus:",
        "Unlimited internal users",
        "Embeddable form",
        "Automated responses & routing",
        "Custom fields",
        "...and more"
    ],
}


const ViewPlans = () => {    

    const { subscription, plans, planChange, getSubscription, getPlans } = useContext(SubscriptionContext)

    const showFreeTrialMessage = useMemo(()=>{
        return !!(subscription && !subscription.plan);
    },[subscription])

    const showPlanMessage = useMemo(()=>{
        return !!(subscription && subscription.plan && subscription.plan.name);
    },[subscription])

    const classes = useStyles();

    useEffect(()=>{
        getSubscription();
        getPlans();
    },[])

    if(!subscription){
        return <PageLoader />
    }

    return (
        <Grid container>
            <Grid item >
                <Container>

                    <FormContainer >

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle align="left">
                                            Plans & Billing
                                        </FormTitle>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormSection >
                                           Your plan
                                        </FormSection>
                                    </Grid>


                                    <Grid item xs={12}>
                                        {showFreeTrialMessage && <div className={classes.description}>
                                            You are on a free trial of the Small Business plan until <strong>{toMediumDate(subscription.trialEndDate)}</strong>.  If you select a plan below, it will end your trial.
                                        </div>}

                                        {showPlanMessage && <div className={classes.description}>
                                            You are on the <strong>{subscription.plan.name}</strong> plan (<strong>R{formatMoney(subscription.plan.monthlyPrice.amount)}</strong>/mo).
                                            {planChange && planChange.plan && <span> You will be changed to the  <strong>{planChange.plan.name}</strong> plan (<strong>R{formatMoney(planChange.plan.monthlyPrice.amount)}/mo</strong>) on <strong>{toMediumDate(planChange.changeDate)}</strong>.</span>}
                                        </div>}
                                    </Grid>


                                    {showPlanMessage && <>
                                    <Grid item xs={12}>
                                        <FormSection >
                                            Billing
                                        </FormSection>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {subscription.plan.monthlyPrice.amount === 0.0 && <div className={classes.description}>
                                            There is no monthly fee.
                                        </div>}

                                        {subscription.plan.monthlyPrice.amount > 0.0 && !planChange && <div className={classes.description}>
                                            You will be billed <strong>R{formatMoney(subscription.plan.monthlyPrice.amount)}</strong> on  <strong>{toMediumDate(subscription.endDate)}</strong>
                                        </div>}

                                        {planChange && planChange.plan && <div className={classes.description}>
                                            You will be billed <strong>R{formatMoney(planChange.plan.monthlyPrice.amount)}</strong> on  <strong>{toMediumDate(planChange.changeDate)}</strong>
                                        </div>}
                                    </Grid>
                                    </>}


                                    {plans && subscription &&
                                    <Grid container item xs={12}>
                                    <Grid container item spacing={5} alignItems="stretch">
                                        {plans.map((plan) => (
                                            // Enterprise card is full width at sm breakpoint
                                            <Grid item key={plan.id} xs={12} md={4} alignItems="stretch">
                                                <Card >
                                                    <CardHeader
                                                        title={plan.name}
                                                        subheader={plan.id === subscription.plan?.id ? 'Your current plan' : ''}
                                                        titleTypographyProps={{align: 'center'}}
                                                        subheaderTypographyProps={{align: 'center', color: '#fff'}}
                                                        className={plan.id === subscription.plan?.id ? classes.cardHeaderCurrent : classes.cardHeader}
                                                    />
                                                    <CardContent>
                                                        <div className={classes.cardPricing}>
                                                            { plan.monthlyPrice &&
                                                                <>
                                                                    <div className={classes.price}>
                                                                        R{formatMoney(plan.monthlyPrice.amount)}
                                                                    </div>
                                                                    <div className={classes.perMonth}>
                                                                        /mo
                                                                    </div>
                                                                </>
                                                            }
                                                            {!plan.monthlyPrice &&
                                                            <>
                                                                <div className={classes.price}>
                                                                    Enquire for pricing
                                                                </div>
                                                            </>
                                                            }
                                                        </div>
                                                        <ul>
                                                            {features[plan.name].map((feature) => (
                                                                <Typography component="li" variant="subtitle1"
                                                                            align="center" key={feature}>
                                                                    {feature}
                                                                </Typography>
                                                            ))}
                                                        </ul>
                                                    </CardContent>
                                                    <CardActions>
                                                        <GetPlanButton plan={plan} subscription={subscription} planChange={planChange}/>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    </Grid>
                                    }

                                </Grid>
                                
                    </FormContainer>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ViewPlans;

import React from "react";
import { CHARACTER_TYPE } from "./PasswordCriteriaItem";
import { isCriteriaMet } from "./utils";

const usePasswordCriteria = (values, fieldName) => {
  const password = values[fieldName];

  const rules =  React.useMemo(() => {
    return [
      {characterType: CHARACTER_TYPE.ANY, numOfCharacters: 8},
      {characterType: CHARACTER_TYPE.UPPERCASE, numOfCharacters: 1},
      {characterType: CHARACTER_TYPE.LOWERCASE, numOfCharacters: 1},
      {characterType: CHARACTER_TYPE.NUMBER, numOfCharacters: 1},
    ]
  },[])


  const isValid = React.useMemo(() => {
    return rules.reduce((result, rule) => {
      return (
        result &&
        isCriteriaMet(rule.characterType, rule.numOfCharacters, password)
      );
    }, true);
  }, [password,rules]);

  return {
    rules,
    password,
    isValid,
  };
};

export default usePasswordCriteria;

import dev from "./dev";
import staging from "./staging";
import prod from "./prod";
import local from "./local";

const envMap = {
    dev: dev,
    staging: staging,
    prod: prod,
    local: local
};

export const stage = () => {
    return process.env.REACT_APP_STAGE || process.env.STAGE ||  "dev";
};

export const isProd = () => {
    return stage() === "prod";
};



const getEnvConfigs = () => {
    return  envMap[stage()];
};

export default getEnvConfigs();

import React, {useContext} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
} from '@material-ui/core';
import Button from 'components/form/Button';
import {FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import PasswordField from "components/form/PasswordField";
import PasswordCriteriaList from "components/form/PasswordCriteriaList";
import {getItem, setItem} from "util/cache";
import Logo from "components/form/Logo";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const INITIAL_FORM_STATE = {
    newPassword: '',
    passwordValid: false
};

const FORM_VALIDATION = Yup.object().shape({
    newPassword: Yup.string()
        .required("New password is required"),
    passwordValid: Yup.boolean()
        .oneOf([true], 'Password does not meet criteria.')
        .required('Password does not meet criteria.'),
});

const ChangePassword = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const authContext = useContext(AuthContext)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>

                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {
                                    setItem("newPassword", values.newPassword);
                                    await authContext.signInWithEmail(getItem("email"), getItem("password"))
                                    enqueueSnackbar(`Password changed`,{
                                        variant: 'success',
                                    })
                                    history.push("/requests")
                                }catch (e){
                                    console.log("Error", e)
                                    enqueueSnackbar(`An error occurred, Please try again`,{
                                        variant: 'error',
                                    })
                                    form.resetForm()
                                }

                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            <Logo url="/logo.svg" />
                                           Change password
                                        </FormTitle>

                                        <FormDescription>
                                            {`Please change your password to continue`}
                                        </FormDescription>



                                    </Grid>


                                    <Grid item xs={12}>
                                        <PasswordField
                                            name="newPassword"
                                            label="New password"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordCriteriaList passwordField="newPassword" passwordValidField="passwordValid"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                        >
                                            Change Password
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;

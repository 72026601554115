import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import { isOk, post_file_to_api } from "util/api";
import BlockUi from "react-block-ui";
import {useField, useFormikContext} from "formik"

import 'assets/css/block-ui.css';

import Dropzone from "react-dropzone"
//const Dropzone = React.lazy(() => import("react-dropzone"));

const useStyles = makeStyles((theme) => ({
    addLogo: {
        fontSize: "20px",
        marginLeft: "8px",
    },
    selectFile: {
        fontSize: "16px",
        textAlign: "center",
        marginBottom: "20px"
    },
    fileFormat: {
        textAlign: "center",
        fontSize: "14px",
    },
    highlight: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    button: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: "16px",
    },
    fileUpload: {
        padding: "20px",
        borderRadius: "16px",
        border: `1px dashed ${theme.palette.primary.main}`,
        cursor: "pointer",
        backgroundColor: "#eee",
        minHeight: "140px",
    },
    dropzone: {
        cursor: "pointer",
        "&:focus": {
            outline: "0",
        },
    },
    image: {
        width: "auto",
        maxWidth: "290px",
        maxHeight: "150px",
        height: "auto",
        marginBottom: "16px",
    },
    imageContainer: {
        width: "auto",
        maxWidth: "310px",
        padding: "8px",
        cursor: "pointer",
        border: "1px solid #fff",
        "& .action-btn": {
            visibility: "hidden",
        },
        "&:hover": {
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            border: "1px dashed #0068AB",
            borderRadius: "16px",
            "& .action-btn": {
                visibility: "visible",
                textAlign: "left",
            },
        },
    },
    uploading: {
        marginTop: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#4E4C6A",
    },
    alert: {
        marginTop: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.main
    }
}));

const imageMaxSize = 10485760; // bytes
const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/svg+xml, image/svg";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
    return item.trim();
});

const ImageLoader = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="center"
        >
            <div>
                <CircularProgress />
            </div>
            <div className={classes.uploading}>Uploading your image</div>
        </Grid>
    );
};

const ImageUploader = (props) => {
    let { name, canRemove } = props;


    const [loading, setLoading] = React.useState(false);

    const { setFieldValue,setFieldError, setFieldTouched} = useFormikContext();
    const [field, meta] = useField(name);

    const classes = useStyles();


    const verifyFile = (files) => {
        if (files && files.length > 0) {
            if (files.length > 1) {
                setFieldError(name, "Sorry, you can only upload one image image");
                return false;
            }
            const currentFile = files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if (currentFileSize > imageMaxSize) {
                setFieldError(name, "File too large. Maximum file size 10MB");
                return false;
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                setFieldError(
                    name,
                    "File format not allowed. Allowed file formats are JPG, PNG and SVG"
                );
                return false;
            }
            return true;
        }
    };

    const handleOnDrop = (files, rejectedFiles) => {
        setFieldTouched(name,true)
        if (files && files.length > 0 && rejectedFiles.length === 0) {
            const isVerified = verifyFile(files);
            if (isVerified) {
                const currentFile = files[0];

                const fileReader = new FileReader();
                setLoading(true);
                fileReader.addEventListener(
                    "load",
                    async () => {
                        let values = new FormData();
                        values.append("file", currentFile);
                        try {
                            let response = await post_file_to_api(
                                currentFile,
                                "/popi-form-api/file/upload",
                                true
                            );
                            if (isOk(response.status)) {
                                handleChange(response.data.url);
                                if (props.onSuccess) {
                                    props.onSuccess(response.data.url);
                                }
                                setLoading(false);
                                setFieldError(name, null);
                            } else {
                                setFieldError(
                                    name,
                                    "Sorry, we experienced an issue adding your image. Please try again"
                                );
                            }
                        } catch (e) {
                            setFieldError(
                                name,
                                "Sorry, we experienced an issue adding your image. Please try again"
                            );
                        } finally {
                            setLoading(false);
                        }
                    },
                    false
                );
                fileReader.readAsDataURL(currentFile);
            }
        } else if (rejectedFiles && rejectedFiles.length > 0) {
            if (rejectedFiles.length > 1) {
                setFieldError(name, "Sorry, you can only upload one image image");
            } else {
                setFieldError(
                    name,
                    "File format not allowed. Allowed file formats are JPG, PNG and SVG"
                );
            }
        }
    };

    const handleChange = (url) => {
        setFieldValue(name, url || '');
    };

    const removeImage = () => {
        handleChange(null);
    };

    const imgSrc = field.value;

    return (
        <React.Fragment>
            {!imgSrc && (
                <BlockUi  blocking={loading} loader={<ImageLoader />}>
                    <Dropzone onDrop={handleOnDrop} multiple={false} accept={"image/*"}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className={classes.dropzone}>
                                <input {...getInputProps()} />

                                <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="center"
                                    justify="space-evenly"
                                    className={classes.fileUpload}
                                >

                                    <Grid className={classes.selectFile}>
                                        <div className={classes.highlight}>Select a file</div> or
                                        drop your image here.
                                    </Grid>

                                    {meta.touched && meta.error && !loading && <Grid>
                                        <div className={classes.error}>{meta.error}</div>
                                    </Grid>}

                                    {!meta.error && (
                                        <Grid
                                            direction="column"
                                            container
                                            item
                                            className={classes.fileFormat}
                                        >
                                            <div>Maximum 10MB in size.</div>
                                            <div>JPG, PNG, or SVG formats.</div>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </BlockUi>
            )}

            {imgSrc && canRemove && (
                <Grid
                    container
                    item
                    direction="column"
                    className={classes.imageContainer}
                    alignItems="flex-start"
                >
                    <div>
                        <img alt="..." className={classes.image} src={imgSrc} />
                    </div>
                    <div className="action-btn">
                        <div className={classes.button} onClick={removeImage}>
                            Remove
                        </div>
                    </div>
                </Grid>
            )}

            {imgSrc && !canRemove && (
                <BlockUi tag="div" blocking={loading} loader={<ImageLoader />}>
                    <Dropzone onDrop={handleOnDrop} multiple={false} accept={"image/*"}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className={classes.dropzone}>
                                <input {...getInputProps()} />

                                <Grid container item direction="column">
                                    <img alt="..." className={classes.image} src={imgSrc} />

                                    <div className={classes.button}>Select new image</div>

                                    {meta.touched && meta.error && <Grid>
                                        <div className={classes.error}>{meta.error}</div>
                                    </Grid>}
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </BlockUi>
            )}
        </React.Fragment>
    );
};

ImageUploader.propTypes = {
    name: PropTypes.string,
    onSuccess: PropTypes.func,
    canRemove: PropTypes.bool,
};

export default ImageUploader;

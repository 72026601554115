import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'
import config from 'configs'
import {getSessionItem, removeSessionItem, SESSION_KEYS, setItem, setSessionItem} from "util/cache";

const userPoolId = config['COGNITO_USERPOOL_ID']
const clientId = config['COGNITO_CLIENT_ID']


const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
}

const userPool = new CognitoUserPool(poolData)

let currentUser = userPool.getCurrentUser()

export function getCurrentUser() {
    return currentUser
}

function getCognitoUser(username) {
    const userData = {
        Username: username,
        Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)

    return cognitoUser
}

export async function getSession() {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser()
    }

    return new Promise(function (resolve, reject) {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err)
            } else {
                setItem(SESSION_KEYS.ACCESS_TOKEN, session.accessToken.jwtToken)
                setItem(SESSION_KEYS.TOKEN_EXP_AT, session.accessToken.payload.exp * 1000)
                setItem(SESSION_KEYS.REFRESH_TOKEN, session.refreshToken.token)
                resolve(session)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function signUpUserWithEmail(username, email , password, attributes) {
    return new Promise(function (resolve, reject) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
        ]

        if(attributes) {
            for (let prop in attributes) {
                if(attributes[prop]) {
                    attributeList.push(
                        new CognitoUserAttribute({
                            Name: prop,
                            Value: attributes[prop],
                        }),
                    )
                }
            }
        }

        userPool.signUp(username, password, attributeList, [], function (err, res) {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function verifyCode(username, code) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username)

        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err)
            } else {
                resolve(result)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function signInWithEmail(username, password) {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: username,
            Password: password,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)

        currentUser = getCognitoUser(username)

        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (session) {

                removeSessionItem(SESSION_KEYS.NEW_PASSWORD)
                setSessionItem(SESSION_KEYS.USERNAME, currentUser.getUsername())
                setItem(SESSION_KEYS.ACCESS_TOKEN, session.accessToken?.jwtToken)
                setItem(SESSION_KEYS.TOKEN_EXP_AT, session.accessToken?.payload.exp * 1000)
                setItem(SESSION_KEYS.REFRESH_TOKEN, session.refreshToken?.token)
                resolve(session)
            },
            onFailure: function (err) {
                setSessionItem(SESSION_KEYS.USERNAME, currentUser.getUsername())
                reject(err)
            },
            newPasswordRequired: function() {
                setSessionItem(SESSION_KEYS.USERNAME, currentUser.getUsername())
                let newPassword = getSessionItem(SESSION_KEYS.NEW_PASSWORD)
                if(newPassword){
                    currentUser.completeNewPasswordChallenge(newPassword, null, this)
                }else{
                    reject({code: "NewPasswordRequired", message: "New Password Required"})
                }

            }
        })
    }).catch((err) => {
        throw err
    })
}

export function signOut() {
    if (currentUser) {
        currentUser.signOut()
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('tokenExpAt')
        window.localStorage.removeItem('refreshToken')
    }
}

export async function getAttributes() {
    return new Promise(function (resolve, reject) {
        currentUser.getUserAttributes(function (err, attributes) {
            if (err) {
                reject(err)
            } else {
                resolve(attributes)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function setAttribute(attribute) {
    return new Promise(function (resolve, reject) {
        const attributeList = []
        const res = new CognitoUserAttribute(attribute)
        attributeList.push(res)

        currentUser.updateAttributes(attributeList, (err, res) => {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function sendCode(username) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username)

        if (!cognitoUser) {
            reject(`could not find ${username}`)
            return
        }

        cognitoUser.resendConfirmationCode(function (err) {
           if(err){
               reject(err)
           }else{
               resolve('code resend')
           }
        })

    }).catch((err) => {
        throw err
    })
}

export async function confirmPassword(username, code, password) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username)

        if (!cognitoUser) {
            reject(`could not find ${username}`)
            return
        }

        cognitoUser.confirmPassword(code, password, {
            onSuccess: function () {
                resolve('password updated')
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    })
}

export async function forgotPassword(username) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username)

        if (!cognitoUser) {
            reject(`could not find ${username}`)
            return
        }

        cognitoUser.forgotPassword( {
            onSuccess: function () {
                resolve('password updated')
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    })
}

export async function changePassword(oldPassword, newPassword) {
    return new Promise(function (resolve, reject) {
        currentUser.changePassword(oldPassword, newPassword, function (err, res) {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    })
}

import React, {useContext, useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Button as MuiButton,
    Container,
    Grid, Link,
} from '@material-ui/core';
import Select from 'components/form/Select';
import Button from 'components/form/Button';
import {FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import FormContainer from "components/containers/FormContainer"
import {get_from_api, isOk, post_to_api} from "util/api";
import PageLoader from "components/containers/PageLoader";
import {useParams} from "react-router";
import {Link as RouterLink, useHistory} from "react-router-dom";
import Textfield from "components/form/Textfield";

const INITIAL_FORM_STATE = {
    firstName: '',
    lastName: '',
    userRole: 'DataOfficer',
    email: '',
    status: 'Active',
};

const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string()
        .required("Firstname is required"),
    lastName: Yup.string()
        .required("Lastname is required"),
    userRole: Yup.string()
        .required("User role is required"),
    status: Yup.string()
        .required("Status is required"),
    email: Yup.string()
        .email('Invalid email.')
        .required('Email is required'),
});

const EditUser = () => {

    let { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [initialFormState, setInitialFormState] = useState(null)
    const [formInitialised, setFormInitialised] = useState(false)

    const history = useHistory();


    useEffect(()=>{
        const getUser = async () => {
            if(id) {
                let response = await get_from_api(`/popi-form-api/users/id/${id}`)
                if (isOk(response.status)) {
                    setInitialFormState(response.data)
                    setFormInitialised(true)
                } else {
                    setInitialFormState(INITIAL_FORM_STATE)
                    setFormInitialised(true)
                }
            }else{
                setFormInitialised(true)
                setInitialFormState(INITIAL_FORM_STATE)
            }
        }
        getUser();
    },[id])

    const activateUser = async (event)=> {
        event.preventDefault();
        let response = await post_to_api(initialFormState,"/popi-form-api/users/activate")
        if(isOk(response.status)) {
            enqueueSnackbar(`User activated`, {
                variant: 'success',
            })
            setInitialFormState(response.data)
            history.push("/users");
        }else{
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }
    }

    const deactivateUser = async (event)=> {
        event.preventDefault();
        let response = await post_to_api(initialFormState,"/popi-form-api/users/deactivate")
        if(isOk(response.status)) {
            enqueueSnackbar(`User deactivated`, {
                variant: 'success',
            })
            setInitialFormState(response.data)
            history.push("/users");
        }else{
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }
    }

    if(!formInitialised){
        return <PageLoader />
    }

    return (
        <Grid container>
            <Grid >
                <Container maxWidth="md">
                    <Link component={RouterLink} to="/users">Back to all users</Link>
                    <FormContainer >

                        <Formik
                            initialValues={initialFormState}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {
                                    let response = await post_to_api(values,"/popi-form-api/users/")
                                    if(isOk(response.status)) {
                                        enqueueSnackbar(`User updated`, {
                                            variant: 'success',
                                        })
                                        form.setValues(response.data)
                                        history.push("/users");
                                    }else{
                                        enqueueSnackbar(response?.data?.message ? response.data.message : `An error occurred, Please try again`,{
                                            variant: 'error',
                                        })
                                    }

                                }catch (e){
                                    console.log("Error", e)
                                    enqueueSnackbar(`An error occurred, Please try again`,{
                                        variant: 'error',
                                    })
                                }

                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>

                                        <FormTitle align="left">
                                            {id ? "Edit user" : "Add user"}
                                        </FormTitle>

                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Textfield
                                            name="firstName"
                                            label="Firstname"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Textfield
                                            name="lastName"
                                            label="Lastname"
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Textfield
                                            name="email"
                                            label="Email"
                                            helperText="User will get email with instructions to login"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Select
                                            name={"userRole"}
                                            label={"User role"}
                                            options={[
                                                {value: "Admin",label: "Admin"},
                                                {value: "DataOfficer",label: "Data officer"},
                                            ]}
                                        />
                                    </Grid>

                                    {initialFormState.id && <Grid item xs={12} md={4}>
                                        {initialFormState.status != "Active" ?
                                            <MuiButton variant='outlined' color="primary" onClick={activateUser} >
                                                Activate User
                                            </MuiButton> :
                                            <MuiButton variant='outlined' color="primary" onClick={deactivateUser} >
                                                Deactivate User
                                            </MuiButton>
                                            }
                                    </Grid>}

                                    <Grid item xs={12}>
                                        <Button
                                        >
                                            Save
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>

                    </FormContainer>
                </Container>
            </Grid>
        </Grid>
    );
};

export default EditUser;

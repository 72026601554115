import React, {useContext, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Link, TextField, Button as MuiButton
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import Button from 'components/form/Button';
import {Error, FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import {isValidEmail} from "util/text";
import {isOk, post_to_api} from "util/api";
import {getSessionItem, SESSION_KEYS, setSessionItem} from "util/cache";
import Logo from "components/form/Logo";


const INITIAL_FORM_STATE = {
    code: ''
};

const FORM_VALIDATION = Yup.object().shape({
    code: Yup.string()
        .required("Verification code is required"),
});


const VerifyAdminEmail = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const email = getSessionItem(SESSION_KEYS.EMAIL) || props.email
    const username = getSessionItem(SESSION_KEYS.USERNAME)

    const authContext = useContext(AuthContext)

    const [emailDialogueOpen, setEmailDialogueOpen] = useState(false);

    const handleClickEmailDialogueOpen = (event) => {
        event.preventDefault();
        setEmailDialogueOpen(true);
    };

    const handleCloseEmailDialogue = () => {
        setEmailDialogueOpen(false);
        setChangingEmail(false);
    };

    const [newEmail, setNewEmail] = React.useState('');
    const [changeEmailError, setChangeEmailError] = React.useState(null);
    const [changingEmail, setChangingEmail] = React.useState(false);
    const handleNewEmailChange = (event) => {
        setNewEmail(event.target.value);
    };

    const changeEmail = async ()=>{
        if(!isValidEmail(newEmail)){
            setChangeEmailError("Email is not valid");
            return;
        }
        setChangeEmailError(null);
        setChangingEmail(true);
        try {
            let response = await post_to_api({
                username: username,
                email: newEmail
            }, "/popi-form-api/users/change-signup-email", false);
            if(isOk(response.status)) {
                if(response.data.success) {
                    enqueueSnackbar(`Email updated, please check your email for code`, {
                        variant: 'success',
                    })
                    setSessionItem(SESSION_KEYS.EMAIL, newEmail)
                    setNewEmail(null)
                    handleCloseEmailDialogue();
                }else {
                    setChangeEmailError(response.data.message);
                }

            }else{
                enqueueSnackbar(`Failed to change email, please try again`,{
                    variant: 'error',
                })
            }
        }catch (e) {
            console.error("Error changing email", e)
            enqueueSnackbar(`Failed to change email, please try again`,{
                variant: 'error',
            })
        }
    }

    const resendCode = async (event)=> {
        event.preventDefault();
        try {
            let response = await post_to_api({
                username: username,
                email: email
            }, "/popi-form-api/users/resend-signup-email", false);
            if(isOk(response.status)) {
                enqueueSnackbar(`Verification email resent, check your email`, {
                    variant: 'success',
                })
            }else {
                enqueueSnackbar(`An error occurred, Please try again`,{
                    variant: 'error',
                })
            }
        }catch (e){
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }
    }



    return (

                <>
                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                        >
                            <Form autocomplete="off">

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            <Logo url="/logo.svg" />
                                        </FormTitle>

                                        <FormDescription>
                                            To confirm your identity, we’ve sent email to <strong>{email}</strong>. Please click the link in the email to verify your account
                                        </FormDescription>

                                        <div style={{marginTop: '20px'}} >&nbsp;</div>

                                        <FormDescription>
                                            Did not receive it? <Link style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={resendCode}>Send it again</Link>
                                        </FormDescription>

                                        <FormDescription>
                                            Entered your email address incorrectly? <Link style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={handleClickEmailDialogueOpen}>Change it</Link>
                                        </FormDescription>

                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>


                        <Dialog open={emailDialogueOpen} onClose={handleCloseEmailDialogue} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Change email</DialogTitle>
                            <DialogContent>
                                <div style={{marginBottom: '14px'}}>
                                    Please enter your email address below.
                                </div>
                                <TextField
                                    autoFocus
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    value={newEmail}
                                    onChange={handleNewEmailChange}
                                    variant = "outlined"
                                    required
                                    fullWidth
                                    inputProps={{
                                        autocomplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                />
                                {changeEmailError && <Error>{changeEmailError}</Error>}
                            </DialogContent>
                            <DialogActions>
                                <MuiButton onClick={handleCloseEmailDialogue} color="primary" disabled={changingEmail}>
                                    Cancel
                                </MuiButton>
                                {!changingEmail && <MuiButton onClick={changeEmail} color="primary">
                                    Change Email
                                </MuiButton>}
                                {changingEmail && <CircularProgress size={24} color="primary" />}
                            </DialogActions>
                        </Dialog>

                    </>


    );
};

export default VerifyAdminEmail;

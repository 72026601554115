import React, {useState} from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle, IconButton, Typography,
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import ContactUs from "components/fragments/contactus";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


const ContactUsPlanButton = (props) => {

    const classes = useStyles();

    let {callback} = props

    const [openDialog, setOpenDialog] = useState(false)

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleClick = async ()=>{
        setOpenDialog(true);

    }

    return (
        <>
            <Button fullWidth variant="contained" color="primary"
                    onClick={handleClick}>
                 Contact Us
            </Button>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="end-of-trial-dialog-title"
            >

                <DialogTitle id="end-of-trial-dialog-title">
                    <Typography variant="h6">&nbsp;</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ContactUs callback={handleClose}/>
                </DialogContent>

            </Dialog>
        </>

    );
};

export default ContactUsPlanButton;
import { CHARACTER_TYPE } from "./PasswordCriteriaItem";

export const isCriteriaMet = (
  characterType,
  numOfCharactersRequired,
  password
) => {
  let pwd = password || "";
  switch (characterType) {
    case CHARACTER_TYPE.NUMBER:
      return (pwd.match(/[0-9]/g) || []).length >= numOfCharactersRequired;
    case CHARACTER_TYPE.LOWERCASE:
      return (pwd.match(/[a-z]/g) || []).length >= numOfCharactersRequired;
    case CHARACTER_TYPE.UPPERCASE:
      return (pwd.match(/[A-Z]/g) || []).length >= numOfCharactersRequired;
    default:
      return pwd.length >= numOfCharactersRequired;
  }
};

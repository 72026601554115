import React from 'react';
import {Button, CircularProgress, makeStyles} from '@material-ui/core';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWrapper = ({
  children,
  disableOnInvalid,
    disableFullWidth,
    callback,
  ...otherProps
}) => {
  const classes = useStyles();
  const { submitForm, isValid, touched, errors, isSubmitting  } = useFormikContext();

  const isDisabled = disableOnInvalid ? (!isValid || (Object.keys(touched).length === 0 || Object.keys(errors).length > 0)) : false

  const handleSubmit = async () => {
    await submitForm();
    if(isValid && callback){
      callback()
    }
  }

  const configButton = {
    variant: 'contained',
    color: 'primary',
    fullWidth: disableFullWidth ? false: true,
    ...otherProps,
    onClick: handleSubmit
  }

  return (
      <div className={classes.wrapper}>
    <Button
      {...configButton}
        disabled={isSubmitting ? true : isDisabled}
    >
      {children}
    </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
  );
};

export default ButtonWrapper;

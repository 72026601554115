import React, { useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
} from '@material-ui/core';
import Button from 'components/form/Button';
import {FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import Textfield from 'components/form/Textfield';
import Logo from "components/form/Logo";
import {isOk, post_to_api} from "util/api";
import VerifyPasswordResetEmail from "pages/verifyPasswordResetEmail";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const INITIAL_FORM_STATE = {
    email: ''
};

const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
    .email('Invalid email.')
    .required('Email is required'),
});

const ForgotPassword = () => {
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState(false);

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();


    const sendEmail = () => {
        setEmail(document.getElementById("email").value);
        setEmailSent(true);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>

                    {!emailSent && <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={async (values, form) => {
                                try {


                                    const response = await post_to_api({email: values.email}, `/popi-form-api/users/forgot-password`)

                                    if (isOk(response.status)) {
                                        enqueueSnackbar(`Email sent`,{
                                            variant: 'success',
                                        })
                                        sendEmail();
                                    } else {
                                        enqueueSnackbar(`An error occurred, Please try again`,{
                                            variant: 'error',
                                        })
                                    }


                                }catch (e){
                                    console.log("Error", e)
                                    enqueueSnackbar(`An error occurred, Please try again`,{
                                        variant: 'error',
                                    })
                                    form.resetForm()
                                }

                            }}
                        >
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormTitle>
                                            <Logo url="/logo.svg" />
                                           Forgot password
                                        </FormTitle>

                                        <FormDescription>
                                            {`Please enter your email to get a reset link`}
                                        </FormDescription>


                                    </Grid>


                                    <Grid item xs={12}>
                                        <Textfield
                                            id="email"
                                            name="email"
                                            label="Email"
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" color="primary" >
                                            Send Reset Email
                                        </Button>
                                    </Grid>


                                </Grid>

                            </Form>
                        </Formik>}
                        {emailSent &&
                            <VerifyPasswordResetEmail newEmail={email} />
                        }

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default ForgotPassword;


import React from 'react';

import RequestsTable from "components/fragments/requests/RequestsTable";

const CompletedRequests = () => {
    return ( <RequestsTable status={"Completed"} />);
};

export default CompletedRequests;

import React from "react";
import {
    Grid, Link,
} from "@material-ui/core";
import {
    Link as RouterLink,
    Route,
    Switch,
    withRouter,
} from "react-router-dom";

// styles
import useStyles from "./styles";


import RequestForm from "pages/requestForm";
import VerifyUserEmail from "pages/verifyUserEmail";
import ThankYou from "pages/thankYou";
import FormNotFound from "components/fragments/userForm/FormNotFound";
import {SubscriptionPlans} from "util/subscriptions";


const FormLayout = (props) => {
    const classes = useStyles();
    const {popiForm} = props;

    const isFreePlan = React.useMemo(()=>{
        let freePlan = false;
        if(popiForm  && popiForm.subscription?.plan?.name === SubscriptionPlans.Free){
            freePlan = true;
        }
        return freePlan;
    },[popiForm])

    if(!popiForm || !popiForm.formFields){
        return <FormNotFound />
    }

    return (
        <Grid container className={classes.container}>
            <div className={classes.formContainer}>
                <div>

                    <Switch>

                        <Route path="/verify-email"  render={(props) => (
                            <VerifyUserEmail {...props} popiForm={popiForm} />
                        )} />
                        <Route path="/thank-you"  render={(props) => (
                            <ThankYou {...props} popiForm={popiForm} />
                        )} />

                        <Route path="/"  render={(props) => (
                            <RequestForm {...props} popiForm={popiForm} />
                        )} />
                    </Switch>

                </div>

            </div>

            {isFreePlan && <>

            <Grid container xs={12} justify="center" alignItems="center" className={classes.imageContainer}>

                <img src="/logo.svg" className={classes.footerImage} />
                <div className={classes.poweredBy}>Powered by <Link className={classes.footerLink} href="https://www.popifox.co.za" target="_blank">POPI Fox</Link></div>
            </Grid>

            <Grid container xs={12}  justify="center" alignItems="center" className={classes.footerContainer}>
                <div className={classes.footerText}>Run a business? <Link className={classes.footerLink} href="https://www.popifox.co.za"  target="_blank">Get your free POPIA request form</Link></div>
            </Grid>
                </>}

            <Grid container xs={12}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
            className={classes.footerContainer}>
                <div  className={classes.footerText}>
                <a class={classes.footerLink + " MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"} component={RouterLink}
                          href="https://www.popifox.co.za/terms-of-service">Terms</a>
                <span className={classes.pipe}>|</span>
                    <a class={classes.footerLink + " MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"} component={RouterLink}
                          href="https://www.popifox.co.za/privacy-policy">Privacy Policy</a>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(FormLayout);

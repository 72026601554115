import React, { useState, useEffect } from 'react'
import {get_from_api, isOk} from "util/api";
import {SubscriptionPlans} from "util/subscriptions";

export const SubscriptionContext = React.createContext({})

const SubscriptionProvider = (props)=>{

    const [subscription, setSubscriptionState] = useState(null);
    const [plans, setPlans] = useState();
    const [planChange, setPlanChange] = useState(null);
    const [formInitialised, setFormInitialised] = useState();

    const getFreePlan = ()=>{
        if(plans){
            let filter = plans.filter(plan => plan.name === SubscriptionPlans.Free)
            if(filter.length > 0){
                return filter[0]
            }
        }
        return null
    }

    const getSmallBusinessPlan = ()=>{
        if(plans){
            let filter = plans.filter(plan => plan.name === SubscriptionPlans.SmallBusiness)
            if(filter.length > 0){
                return filter[0]
            }
        }
        return null
    }

    const getPlans = async () => {
        let response = await get_from_api("/popi-form-api/plans/")
        if(isOk(response.status)) {
            setPlans(response.data)
        }
    }

    const getPlanChange = async () => {
        let response = await get_from_api("/popi-form-api/subscriptions/plan-change")
        if(isOk(response.status)) {
            setPlanChange(response.data)
        }
    }

    const getSubscription = async ()=>{
        let response =  await get_from_api(`/popi-form-api/subscriptions/`)
        if(isOk(response.status)){
            setSubscriptionState(response.data)
        }
        getPlanChange();
        setFormInitialised(true);
    }
    const onFreePlan = ()=>{
        return !!(subscription && subscription.plan && subscription.plan.name === SubscriptionPlans.Free);

    }

    const setSubscription = (subscription) => {
        setSubscriptionState(subscription)
        getPlanChange();
    }

    useEffect(()=>{
        if(!subscription) {
            getSubscription();
            getPlans();
        }
    },[])

    return <SubscriptionContext.Provider value={{subscription,formInitialised, setSubscription, plans,planChange, setPlans, onFreePlan, getFreePlan,getSmallBusinessPlan, getSubscription, getPlans }}>{props.children}</SubscriptionContext.Provider>
}

export default SubscriptionProvider
import React, {useContext, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Link, TextField, Button as MuiButton
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import Button from 'components/form/Button';
import {Error, FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import {getItem, getSessionItem, SESSION_KEYS, setSessionItem} from "util/cache";
import PasswordField from "components/form/PasswordField";
import PasswordCriteriaList from 'components/form/PasswordCriteriaList';
import {isOk, post_to_api} from "util/api";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const VerifyPasswordResetEmail = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const resendCode = async (event)=> {
        event.preventDefault();

        try {
            const response = await post_to_api({email: getItem("email")}, `/popi-form-api/users/forgot-password`)

            if (isOk(response.status)) {
                enqueueSnackbar(`Email sent`,{
                    variant: 'success',
                })
            } else {
                enqueueSnackbar(`An error occurred, Please try again`,{
                    variant: 'error',
                })
            }

        }catch (e){
            console.log("Error", e)
            enqueueSnackbar(`An error occurred, Please try again`,{
                variant: 'error',
            })
        }


    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>


                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormTitle>
                                            Password reset
                                        </FormTitle>

                                        <FormDescription>
                                            We have sent a reset password email to <strong>{props.newEmail}</strong>. Please click the link in the email to reset your password.
                                        </FormDescription>

                                        <div style={{marginTop: '20px'}} >&nbsp;</div>

                                        <FormDescription>
                                            Didn’t receive it? <Link style={{fontFamily:'DM Sans,sans-serif',fontSize:'16px'}} component="button" onClick={resendCode}>Send it again</Link>
                                        </FormDescription>

                                    </Grid>


                                </Grid>






                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default VerifyPasswordResetEmail;

import React, {useEffect} from 'react';
import { Formik, Form } from 'formik';
import {
    Container,
    Grid, Link
} from '@material-ui/core';
import Button from 'components/form/Button';
import CardFormWrapper from "components/containers/CardFormWrapper";
import {getYupSchemaFromMetaData, getDefaultInitValues} from "components/containers/DynamicForm/YupSchemaBuilder";
import DynamicForm from "components/containers/DynamicForm";
import {FormDescription, FormTitle, FormTitleHelper} from "components/typography";
import { useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {isOk, post_to_api} from "util/api";
import {setItem} from "util/cache";
import Logo from "components/form/Logo";
import ReactGA from "react-ga";


const RequestForm = (props) => {
    const {popiForm}= props;

    useEffect(()=>{
        ReactGA.pageview('/data-request');
    },[])

    const formSchema = getYupSchemaFromMetaData(popiForm.formFields, [], []);
    const initValues = getDefaultInitValues(popiForm.formFields);

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <CardFormWrapper>



                        <Formik

                            validationSchema={formSchema}
                            initialValues={initValues}
                            onSubmit={async values => {
                               let dataRequest =  {}
                                dataRequest.business = popiForm.business;
                                dataRequest.email = values.email;
                                dataRequest.data = values;
                                dataRequest.dataRequestType = values.dataRequestType;
                                dataRequest.dataSubjectName = values.name;

                                try {
                                    let response = await post_to_api(dataRequest, "/popi-form-api/user-form/data-request", false);
                                    if(isOk(response.status)) {
                                        setItem("requestId", response.data.requestId)
                                        setItem("requestEmail", values.email)
                                        history.push("/verify-email")
                                    }else{
                                        enqueueSnackbar(`Request failed to submit, please try again`,{
                                            variant: 'error',
                                        })
                                    }
                                }catch (e) {
                                    console.error("Error signing up", e)
                                    enqueueSnackbar(`Request failed to submit, please try again`,{
                                        variant: 'error',
                                    })
                                }
                            }}
                        >
                            <Form>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Logo url={popiForm.logo} />

                                        <FormTitle>Exercise your POPIA rights</FormTitle>

                                        <FormDescription>
                                            Send your personal data request to {`${popiForm.business.name}`} via the form below.
                                            Your request will be sent to the Data Protection Officer at <Link href={`https://${popiForm.business.website}`} target="_blank" rel="noopener" >{`${popiForm.business.name}`}</Link>.
                                        </FormDescription>

                                        <FormTitleHelper>
                                            &nbsp;
                                        </FormTitleHelper>
                                    </Grid>

                                    <Grid item container spacing={2} xs={12}>
                                        <DynamicForm config={popiForm.formFields} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button>
                                            Submit
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Form>
                        </Formik>

                    </CardFormWrapper>



                </Container>


            </Grid>

        </Grid>
    );
};

export default RequestForm;

import React from 'react';
import {
    Container,
    Grid
} from '@material-ui/core';
import CardFormWrapper from "components/containers/CardFormWrapper";
import { FormTitle} from "components/typography";


const NotAuthorised = () => {


    return (

        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <CardFormWrapper>

                        <div>

                            <Grid container spacing={2}>



                                <Grid item xs={12}>


                                    <FormTitle>
                                        Not Authorised
                                    </FormTitle>


                                </Grid>


                            </Grid>

                        </div>


                    </CardFormWrapper>
                </Container>
            </Grid>
        </Grid>

    );
};

export default NotAuthorised;

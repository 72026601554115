import React, {useMemo} from 'react';
import ContactUsPlanButton from "components/form/GetPlanButton/ContactUsPlanButton";
import FreePlanButton from "components/form/GetPlanButton/FreePlanButton";
import PaidPlanButton from "components/form/GetPlanButton/PaidPlanButton";
import {Button} from "@material-ui/core";

const GetPlanButton = (props) => {

    let {plan, subscription} = props

    const showContactUsButton = useMemo(()=>{
        return !plan.monthlyPrice;
    }, [plan])


    const showFreePlanButton = useMemo(()=>{
        return plan.monthlyPrice && plan.monthlyPrice.amount === 0;
    }, [plan])


    const showPaidPlanButton = useMemo(()=>{
        return plan.monthlyPrice && plan.monthlyPrice.amount > 0;
    }, [plan])

    if(subscription.plan && plan.id === subscription.plan.id){
        return  <>
            <Button fullWidth variant="outlined" color="primary"
                    >
                Current plan
            </Button>
        </>;
    }

    return (

        <>
            {showContactUsButton &&
            <ContactUsPlanButton {...props}/>
            }

            {showFreePlanButton &&
            <FreePlanButton {...props}/>
            }

            {showPaidPlanButton &&
            <PaidPlanButton {...props}/>
            }

        </>

    );
}

export default GetPlanButton;
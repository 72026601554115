import React, {useContext, useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Link, TextField, Button as MuiButton
} from '@material-ui/core';
import Textfield from 'components/form/Textfield';
import Button from 'components/form/Button';
import {Error, FormDescription, FormTitle} from "components/typography";
import { useSnackbar } from 'notistack';
import {AuthContext} from "context/authContext";
import {useHistory} from "react-router-dom";
import {getItem, getSessionItem, SESSION_KEYS, setSessionItem} from "util/cache";
import PasswordField from "components/form/PasswordField";
import PasswordCriteriaList from 'components/form/PasswordCriteriaList';
import {isOk, post_to_api} from "util/api";
import Logo from "components/form/Logo";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
}));

const INITIAL_FORM_STATE = {
};



const VerifyPasswordReset = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <div className={classes.formWrapper}>

                        <FormTitle>
                            <Logo url="/logo.svg" />
                            Reset password
                        </FormTitle>

                        <FormDescription>
                            {`Please enter your new password`}
                        </FormDescription>

                        <Formik
                            initialValues={{
                                ...INITIAL_FORM_STATE
                            }}
                            onSubmit={async (values, form) => {
                                try {

                                    const response = await post_to_api({token: props.token, newPassword: values.newPassword}, `/popi-form-api/users/reset-password`)

                                    if (isOk(response.status)) {
                                        enqueueSnackbar(`Password changed, Please login`,{
                                            variant: 'success',
                                        })
                                        history.push("/login")
                                    } else {
                                        enqueueSnackbar(`An error occurred, Please try again`,{
                                            variant: 'error',
                                        })
                                    }
                                 }catch (e){
                                     enqueueSnackbar(`An error occurred, Please try again`,{
                                         variant: 'error',
                                     })
                                     form.resetForm()
                                 }

                            }}
                        >
                            <Form autocomplete="off">

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <PasswordField
                                            name="newPassword"
                                            label="New password"
                                            inputProps={{
                                                autocomplete: 'new-password',
                                                form: {
                                                    autocomplete: 'off',
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordCriteriaList passwordField="newPassword" passwordValidField="passwordValid"/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button>
                                            Reset Password
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Form>
                        </Formik>

                    </div>
                </Container>
            </Grid>
        </Grid>
    );
};

export default VerifyPasswordReset;

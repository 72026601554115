import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import { LinearProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        width: "100%",
        padding: theme.spacing(16),
        backgroundColor: '#FFFFFF'
    },
}));

const PageLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.loaderContainer}>
            <LinearProgress />
        </div>
    )
}

export default PageLoader